import React from "react";
import Card from "react-bootstrap/Card";
import HardRet from "../../Asset/Images/Other/hard-ret.svg";
import ReportAccounts from "../../Asset/Images/Other/report-accounts.svg";
import ReportCalendar from "../../Asset/Images/Other/report-calendar.svg";
import ReportEmail from "../../Asset/Images/Other/report-email.svg";
import ReportNormal from "../../Asset/Images/Other/report-normal.svg";
import ReportNots from "../../Asset/Images/Other/report-nots.svg";
import ReportUserCall from "../../Asset/Images/Other/report-user-call.svg";
import ReportUser from "../../Asset/Images/Other/report-user.svg";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import { Col, Row } from "react-bootstrap";
import TableWithoutPaggination from "../../Component/Table/TableWithoutPaggination";
import { CommonService } from "../../Services/CommonServices";
import { useNavigate } from "react-router-dom";
import { classNames } from "react-select/dist/declarations/src/utils";
import moment from "moment";
ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function MultiPropertyWeeklyReportUserData(item) {
  let page_rights = CommonService.getPageRight("Account");

  let Telemarketing: any = [];
  let navigate = useNavigate();

  // ------------------------ New grid Start----------------

  // Grid 1
  const newTentativeOppoTableColumns = React.useMemo(
    () => [
      {
        Header: "Oppo. Type",
        accessor: "opportunityType",
      },
      {
        Header: "Oppo. Name",
        accessor: "opportunityName",
      },
      {
        Header: "Anticipated Revenue",
        accessor: "totalAnticipated",
      },
    ],
    []
  );
  const newTentativeOppoTableData: any = [];
  if (
    item.item?.lstPreviousTentativeOpportunities !== undefined &&
    item.item?.lstPreviousTentativeOpportunities !== null &&
    item.item?.lstPreviousTentativeOpportunities.length > 0
  ) {
    item.item.lstPreviousTentativeOpportunities.map((x: any) => {
      newTentativeOppoTableData.push({
        className: {
          totalAnticipated: "rightAlignClass text-end",
          // totalOpportunities: "centerAlignClass text-center",
        },
        opportunityType: x.OpportunityType,
        opportunityName: x.OpportunityName,
        totalAnticipated: x.TotalAnticipatedRevenue,
      });
    });
  }

  // Grid 2
  const newClosedOppoTableColumns = React.useMemo(
    () => [
      {
        Header: "Oppo. Type",
        accessor: "opportunityType",
      },
      {
        Header: "Oppo. Name",
        accessor: "opportunityName",
      },
      {
        Header: "Anticipated Revenue",
        accessor: "totalAnticipated",
      },
    ],
    []
  );
  const newClosedOppoTableData: any = [];
  if (
    item.item.lstPreviousClosedOpportunities !== undefined &&
    item.item.lstPreviousClosedOpportunities !== null &&
    item.item.lstPreviousClosedOpportunities.length > 0
  ) {
    item.item.lstPreviousClosedOpportunities.map((x: any) => {
      newClosedOppoTableData.push({
        className: {
          totalAnticipated: "rightAlignClass text-end",
          // totalOpportunities: "centerAlignClass text-center",
        },
        opportunityType: x.OpportunityType,
        opportunityName: x.OpportunityName,
        totalAnticipated: x.TotalAnticipatedRevenue,
      });
    });
  }

  // Grid 3
  const newLostBusinessTableColumns = React.useMemo(
    () => [
      {
        Header: "Oppo. Type",
        accessor: "opportunityType",
      },
      {
        Header: "Oppo. Name",
        accessor: "opportunityName",
      },
      {
        Header: "Anticipated Revenue",
        accessor: "totalAnticipated",
      },
      {
        Header: "Lost Reason",
        accessor: "lostReason",
      },
    ],
    []
  );
  const newLostBusinessTableData: any = [];

  if (
    item.item.lstPreviousLostBusinessOpportunities !== undefined &&
    item.item.lstPreviousLostBusinessOpportunities !== null &&
    item.item.lstPreviousLostBusinessOpportunities.length > 0
  ) {
    item.item.lstPreviousLostBusinessOpportunities.map((x: any) => {
      newLostBusinessTableData.push({
        className: {
          totalAnticipated: "rightAlignClass text-end",
          // totalOpportunities: "centerAlignClass text-center",
        },
        opportunityType: x.OpportunityType,
        opportunityName: x.OpportunityName,
        totalAnticipated: x.TotalAnticipatedRevenue,
        lostReason: x.LostReason,
      });
    });
  }

  // ------------------------ New grid End----------------

  //Tentive
  const TentativeOppoTableColumns = React.useMemo(
    () => [
      {
        Header: "Opportunity Type",
        accessor: "opportunityType",
      },
      {
        Header: "Total Anticipated Revenue",
        accessor: "totalAnticipated",
      },
      {
        Header: "Total Opportunitie(s)",
        accessor: "totalOpportunities",
      },
    ],
    []
  );

  var rightAlignClass = { textAlign: "right" };
  var centerAlignClass = { textAlign: "center" };

  const TentativeOppoTableData: any = [];
  if (
    item.item.lstTentativeOpportunityData !== undefined &&
    item.item.lstTentativeOpportunityData !== null &&
    item.item.lstTentativeOpportunityData.length > 0
  ) {
    item.item.lstTentativeOpportunityData.map((x: any) => {
      TentativeOppoTableData.push({
        className: {
          totalAnticipated: "rightAlignClass text-end",
          totalOpportunities: "centerAlignClass text-center",
        },
        opportunityType: x.OpportunityType,
        totalAnticipated: x.TotalAnticipatedRevenue,
        totalOpportunities: x.TotalOpportunities,
      });
    });
  }

  if (
    item.item.lstActivityCompletedData !== undefined &&
    item.item.lstActivityCompletedData !== null &&
    item.item.lstActivityCompletedData.length > 0
  ) {
    item.item.lstActivityCompletedData.map((x: any) => {
      if (x.ActivityType_Term === "Telemarketing")
        Telemarketing.push({
          ActivityType_Term: x.ActivityType_Term,
        });
    });
  }

  //Closed
  const ClosedOppoTableColumns = React.useMemo(
    () => [
      {
        Header: "Opportunity Type",
        accessor: "opportunityType",
      },
      {
        Header: "Total Anticipated Revenue",
        accessor: "totalAnticipated",
      },
      {
        Header: "Total Opportunitie(s)",
        accessor: "totalOpportunities",
      },
    ],
    []
  );
  const ClosedOppoTableData: any = [];
  if (
    item.item.lstCloseOpportunityData !== undefined &&
    item.item.lstCloseOpportunityData !== null &&
    item.item.lstCloseOpportunityData.length > 0
  ) {
    item.item.lstCloseOpportunityData.map((item: any) => {
      ClosedOppoTableData.push({
        className: {
          totalAnticipated: "rightAlignClass text-end",
          totalOpportunities: "centerAlignClass text-center",
        },
        opportunityType: item.OpportunityType,
        totalAnticipated: item.TotalAnticipatedRevenue,
        totalOpportunities: item.TotalOpportunities,
      });
    });
  }
  //new
  const newOppoTableColumns = React.useMemo(
    () => [
      {
        Header: "Oppo Type",
        accessor: "OpportunityType",
      },
      {
        Header: "Oppo Name",
        accessor: "OppoName",
      },
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "Status",
        accessor: "Status",
      },
      {
        Header: "Total Anticipated Revenue",
        accessor: "TotalAnticipatedRevenue",
      },
      {
        Header: "Lost Reason",
        accessor: "LostReason",
      },
    ],
    []
  );

  const newOppoTableData: any = [];
  item.item.lstNewOpportunityData !== undefined &&
    item.item.lstNewOpportunityData !== null &&
    item.item.lstNewOpportunityData.length > 0 &&
    item.item.lstNewOpportunityData.map((item: any) => {
      newOppoTableData.push({
        className: {
          TotalAnticipatedRevenue: "rightAlignClass text-end",
        },
        OppoName: item.OppoName,
        Source: item.Source,
        Status: item.Status,
        OpportunityType: item.OpportunityType,
        TotalAnticipatedRevenue: item.TotalAnticipatedRevenue,
        LostReason: item.LostReason,
      });
    });

  //Lost Business
  const LostBusinessTableColumns = React.useMemo(
    () => [
      {
        Header: "Opportunity Type",
        accessor: "opportunityType",
      },
      {
        Header: "Total Anticipated Revenue",
        accessor: "totalAnticipated",
      },
      {
        Header: "Total Opportunitie(s)",
        accessor: "totalOpportunities",
      },
    ],
    []
  );
  const LostBusinessTableData: any = [];
  item.item.lstLostOpportunityData !== undefined &&
    item.item.lstLostOpportunityData !== null &&
    item.item.lstLostOpportunityData.length > 0 &&
    item.item.lstLostOpportunityData.map((item: any) => {
      LostBusinessTableData.push({
        className: {
          totalAnticipated: "rightAlignClass text-end",
          totalOpportunities: "centerAlignClass text-center",
        },
        opportunityType: item.OpportunityType,
        totalAnticipated: item.TotalAnticipatedRevenue,
        totalOpportunities: item.TotalOpportunities,
      });
    });

  const FocousPreviousAccountDataHeader = React.useMemo(
    () => [
      {
        Header: "Account Name",
        accessor: "accountName",
      },
    ],
    []
  );

  let FocousPreviousAccountTableData: any = [];

  item.item.lstFocusPreviousAccountData !== undefined &&
    item.item.lstFocusPreviousAccountData !== null &&
    item.item.lstFocusPreviousAccountData.length > 0 &&
    item.item.lstFocusPreviousAccountData.map((item: any) => {
      FocousPreviousAccountTableData.push({
        controlRender: {
          accountName: (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                if (page_rights.is_view) {
                  navigate("/accounts-view", {
                    state: { AccountID: item.AccountID, AccountName: item.AccountName },
                  });
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
              onKeyDown={() => {
                if (page_rights.is_view) {
                  navigate("/accounts-view", {
                    state: { AccountID: item.AccountID, AccountName: item.AccountName },
                  });
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            >
              {item.AccountName}
            </div>
          ),
        },
        accountName: item.AccountName,
      });
    });

  //#region Donut Chart Setup
  let donutData: any = [];
  let donutLabels: any = [];

  item.item.lstActivityCompletedDataCount !== undefined &&
    item.item.lstActivityCompletedDataCount &&
    item.item.lstActivityCompletedDataCount.map((itm) => {
      donutLabels.push(itm.ActivityType_Term + " (" + itm.CompletedCount + ")");
      donutData.push(itm.CompletedCount);
    });

  const data = {
    labels: donutLabels,
    datasets: [
      {
        label: donutLabels,
        data: donutData,
        backgroundColor: [
          "#4dc9f6",
          "#f67019",
          "#f53794",
          "#537bc4",
          "#acc236",
          "#166a8f",
          "#00a950",
          "#58595b",
          "#8549ba",
        ],
        borderColor: [
          "#4dc9f6",
          "#f67019",
          "#f53794",
          "#537bc4",
          "#acc236",
          "#166a8f",
          "#00a950",
          "#58595b",
          "#8549ba",
        ],
        borderWidth: 1,
      },
    ],
  };

  const monthNamesDonut = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dDonut = new Date();

  const DonutOptions = {
    responsive: true,
    plugins: {
      // render: 'value',

      // precision for percentage, default is 0
      precision: 0,
      legend: {
        position: "top",
      },
      title: {
        display: true,
        font: {
          size: 20,
        },
        // text: monthNamesDonut[dDonut.getMonth()] + " " + "Completed Activity",
        // text: "Activity by Type - last",
        text: "Activities",
      },
    },
  };

  //#endregion

  //#region Bar Chart Setup :

  // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  let BarChartData: any = [];
  const datacompletedgoal: any = [],
    dataremaininggoal: any = [],
    dataextragoal: any = [],
    datatotalgoal: any = [];

  item.item.lstActivityGoalData !== undefined &&
    item.item.lstActivityGoalData &&
    item.item.lstActivityGoalData.map((itm) => {
      BarChartData.push(itm.ActivityType_Term);

      datacompletedgoal.push(itm.ClientVisitCompleteGoal);
      datacompletedgoal.push(itm.SiteVisitCompleteGoal);
      datacompletedgoal.push(itm.BlitzCompleteGoal);
      datacompletedgoal.push(itm.TelemarketingCompleteGoal);
      datacompletedgoal.push(itm.NetworkingCompleteGoal);

      dataextragoal.push(itm.ClientVisitExtraGoal);
      dataextragoal.push(itm.SiteVisitExtraGoal);
      dataextragoal.push(itm.BlitzExtraGoal);
      dataextragoal.push(itm.TelemarketingExtraGoal);
      dataextragoal.push(itm.NetworkingExtraGoal);

      dataremaininggoal.push(itm.ClientVisitRemainingGoal);
      dataremaininggoal.push(itm.SiteVisitRemainingGoal);
      dataremaininggoal.push(itm.BlitzRemainingGoal);
      dataremaininggoal.push(itm.TelemarketingRemainingGoal);
      dataremaininggoal.push(itm.NetworkingRemainingGoal);

      datatotalgoal.push(itm.ClientVisitTotalGoal);
      datatotalgoal.push(itm.SiteVisitTotalGoal);
      datatotalgoal.push(itm.BlitzTotalGoal);
      datatotalgoal.push(itm.TelemarketingTotalGoal);
      datatotalgoal.push(itm.NetworkingTotalGoal);
    });

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();

  const BarOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        font: {
          size: 20,
        },
        text: monthNames[d.getMonth()] + " " + "Productivity Goals",
      },
    },
  };

  const labels = ["ClientVisit", "SiteVisit", "Blitz", "Telemarketing", "Networking"];
  const BarData = {
    labels: labels,
    datasets: [
      {
        label: "Total Goals",
        data: datatotalgoal,
        backgroundColor: "#cc0000",
      },
      {
        label: "Complete Goals",
        data: datacompletedgoal,
        backgroundColor: "green",
      },
      {
        label: "Remaining Goals",
        data: dataremaininggoal,
        backgroundColor: "#94b03f",
      },
      {
        label: "Extra Goals",
        data: dataextragoal,
        backgroundColor: "blue",
      },
    ],
  };

  //#endregion
  let page_rights_Account = CommonService.getPageRight("Account");
  let page_rights_LNR = CommonService.getPageRight("LNR");
  let page_rights_RFP = CommonService.getPageRight("RFP");
  let page_rights_Group = CommonService.getPageRight("Group");
  let page_rights_MeetingAndCatering = CommonService.getPageRight("MeetingAndCatering");
  let page_rights_Contacts = CommonService.getPageRight("Contacts");
  let page_rights_Lead = CommonService.getPageRight("Lead");

  function handleNavigate(itm) {
    if (itm.AssociationType_Term.toLowerCase() == "rfp oppo") {
      if (page_rights_RFP.is_view) {
        navigate("/Opportunity/RFP/details", { state: { OppoID: itm.AssociationID } });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "lnr oppo") {
      if (page_rights_LNR.is_view) {
        navigate("/Opportunity/LNR/details", { state: { LNROppoID: itm.AssociationID } });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "group oppo") {
      if (page_rights_Group.is_view) {
        navigate("/Opportunity/Group/details", { state: { GroupOppoID: itm.AssociationID } });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "mac oppo") {
      if (page_rights_MeetingAndCatering.is_view) {
        navigate("/Opportunity/MeetingAndCatering/details", {
          state: { MACOppoID: itm.AssociationID },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "lead") {
      if (page_rights_Lead.is_view) {
        navigate("/suspect/details", {
          state: { leadID: itm.AssociationID, leadName: itm.AccountName },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "contact") {
      if (page_rights_Contacts.is_view) {
        navigate("/contact/details", {
          state: { ContactID: itm.AssociationID, ContactName: itm.AccountName },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "account") {
      if (page_rights_Account.is_view) {
        navigate("/accounts-view", {
          state: { AccountID: itm.AssociationID, AccountName: itm.AccountName },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "accounts") {
      if (page_rights_Account.is_view) {
        navigate("/accounts-view", {
          state: { AccountID: itm.AssociationID, AccountName: itm.AccountName },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
  }

  return (
    <>
      <div className="report-col">
        <div className="report-title">
          {/* {item.item.userData.PropertyLogo !== undefined && item.item.userData.PropertyLogo ? (
            <div className="text-center mt-3">
              <img
                src={item.item.userData.PropertyLogo}
                style={{ width: "200px", maxHeight: "150px" }}
                alt=""
              />
            </div>
          ) : (
            ""
          )} */}
          <span>{item.item.userData && item.item.userData.UserName}</span>
        </div>
        {item.item.userData.PropertyName !== undefined && item.item.userData.PropertyName ? (
          <p className="text-center mt-3" style={{ fontSize: "10px" }}>
            {item.item.userData && item.item.userData.PropertyName}
          </p>
        ) : (
          ""
        )}

        <Row className="mt-4">
          <Col md={4} xl={4} className="mb-3">
            {/* <div className="white-box graph-box-middle h-100">Add Chart</div> */}
          </Col>
          {item.item.lstActivityCompletedDataCount.length ? (
            <Col md={4} xl={4} className="mb-3">
              <div className="white-box graph-box-middle h-100">
                {" "}
                <Doughnut options={DonutOptions} data={data} />
              </div>
            </Col>
          ) : (
            ""
          )}
          <Col md={4} xl={4} className="mb-3">
            {/* <div className="white-box graph-box-middle h-100">Add Chart</div> */}
          </Col>
          {/* {item.item.lstActivityGoalData.length ? (
            <Col md={6} xl={8} className="mb-3">
              <div className="white-box graph-box-middle h-100">
                {" "}
                <Bar options={BarOptions} data={BarData} />
              </div>
            </Col>
          ) : (
            ""
          )} */}

          {/* <Col md={6} xl={4} className="mb-3"> */}
          {/* <div className="white-box graph-box-middle h-100">Add Chart</div> */}
          {/* </Col> */}
        </Row>
        <Row className="mt-4">
          <Col lg={4}>
            <div className="table-title"> Previous Tentative Opportunities </div>
            <TableWithoutPaggination
              columnsData={TentativeOppoTableColumns}
              tableData={TentativeOppoTableData}
              isData={false}
            />
          </Col>
          <Col lg={4}>
            <div className="table-title"> Previous Closed Opportunities </div>
            <div className="">
              <TableWithoutPaggination
                columnsData={ClosedOppoTableColumns}
                tableData={ClosedOppoTableData}
                isData={false}
              />
            </div>
          </Col>
          <Col lg={4}>
            <div className="table-title"> Previous Lost Business Opportunities </div>
            <div className="">
              <TableWithoutPaggination
                columnsData={LostBusinessTableColumns}
                tableData={LostBusinessTableData}
                isData={false}
              />
            </div>
          </Col>
          <Col lg={12} className="mb-4 mt-3">
            <div className="table-title">Previous Focus Accounts & Leads </div>
            <div className="col-title-bar marginbottom-10px">Account Name</div>
            <div className="col-box-bar">
              {item.item.lstFocusPreviousAccountData !== undefined &&
              item.item.lstFocusPreviousAccountData !== null &&
              item.item.lstFocusPreviousAccountData.length > 0
                ? item.item.lstFocusPreviousAccountData.map((item: any) => (
                    <>
                      <label
                        className="report-label"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (page_rights.is_view) {
                            navigate("/accounts-view", {
                              state: { AccountID: item.AccountID, AccountName: item.AccountName },
                            });
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                        onKeyDown={() => {
                          if (page_rights.is_view) {
                            navigate("/accounts-view", {
                              state: { AccountID: item.AccountID, AccountName: item.AccountName },
                            });
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                      >
                        {item.AccountName ? item.AccountName.replace("#", " ") : ""}
                      </label>
                    </>
                  ))
                : "No Record Found"}
            </div>
          </Col>
          <Col lg={12}>
            <div className="table-title"> New Opportunities Created </div>
            {/* {item.item !== undefined &&
            item.item &&
                    item.item.lstActivityCompletedDataCount.map((myCount) => (
                      <>
                        <Col md={12}>
                          <div className="information-division">
                            <h3 className="inner-header m-0">
                              {" "}
                              {myCount.ActivityType_Term} : {myCount.CompletedCount}
                            </h3>
                          </div>
                        </Col>
           </>))
            } */}

            <div className="">
              <TableWithoutPaggination
                columnsData={newOppoTableColumns}
                tableData={newOppoTableData}
                isData={false}
              />
            </div>
          </Col>
        </Row>

        {/*----------------------------- Add New Grids -------------------------- */}
        <Row className="mt-4">
          <Col lg={12}>
            <div className="table-title"> Previous Tentative Opportunities </div>
            <TableWithoutPaggination
              columnsData={newTentativeOppoTableColumns}
              tableData={newTentativeOppoTableData}
              isData={false}
            />
          </Col>
          <Col lg={12}>
            <div className="table-title"> Previous Closed Opportunities </div>
            <div className="">
              <TableWithoutPaggination
                columnsData={newClosedOppoTableColumns}
                tableData={newClosedOppoTableData}
                isData={false}
              />
            </div>
          </Col>
          <Col lg={12}>
            <div className="table-title"> Previous Lost Business Opportunities </div>
            <div className="">
              <TableWithoutPaggination
                columnsData={newLostBusinessTableColumns}
                tableData={newLostBusinessTableData}
                isData={false}
              />
            </div>
          </Col>
        </Row>
        {/* <br /> */}

        {item.handleActiviityData.isIncludeActivity === true ? (
          <Row>
            {item.item.lstActivityCompletedDataCount.length ? (
              <>
                <div className="table-title">
                  <h4 className="mt-5" style={{ fontWeight: "600" }}>
                    Previous Activities
                    {`(${
                      item.item.dateData.WeekStartDate + " - " + item.item.dateData.WeekEndDate
                    })`}
                  </h4>
                  <div className="d-flex">
                    <h6>Total Completed Activities &nbsp; {":"} &nbsp; </h6>
                    {item.item.lstActivityCompletedDataCount.length &&
                      item.item.lstActivityCompletedDataCount.map((itm) => (
                        <h6 className="mb-2">
                          {" "}
                          {itm.ActivityType_Term} {"(" + itm.CompletedCount + ") " + ","}
                        </h6>
                      ))}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="report-col">
              <Col md={12}>
                <Row>
                  {item.item.lstActivityCompletedDataCount &&
                    item.item.lstActivityCompletedDataCount.map((myCount) => (
                      <>
                        <div className="col-title-bar marginbottom-10px">
                          {myCount.ActivityType_Term} : {myCount.CompletedCount}
                        </div>

                        {item.item.lstActivityCompletedData &&
                          item.item.lstActivityCompletedData.map((myActivityData) =>
                            myActivityData.ActivityType_Term === myCount.ActivityType_Term ? (
                              <>
                                <Col lg={12} xl={12} className="mb-3">
                                  <Card className="border-0 h-100">
                                    <Card.Body>
                                      <Row className="marginbottom-10px">
                                        <Col xs={9}>
                                          <div className="d-flex flex-direction-row align-items-start">
                                            <div className="reports-icons me-2">
                                              <img src={HardRet} alt="" />
                                            </div>
                                            <div className="report-card-title">
                                              {/* Call Mr Horner for details, then refer to groups on site */}
                                              {myActivityData.ActivitySubject}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col
                                          xs={3}
                                          className="d-flex justify-content-end align-items-start"
                                        >
                                          <label className="success-label">
                                            {myActivityData.ActivityStatus_Term}
                                          </label>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col xs={9} className="marginbottom-10px">
                                          <div className="d-flex flex-direction-row align-items-center">
                                            <div className="reports-icons me-2">
                                              <img src={ReportUser} alt="" />
                                            </div>
                                            <div
                                              className="report-content-one report-user-content"
                                              role="button"
                                              tabIndex={-1}
                                              style={{ cursor: "pointer", color: "blue" }}
                                              onClick={() => {
                                                handleNavigate(myActivityData);
                                              }}
                                              onKeyDown={() => {
                                                handleNavigate(myActivityData);
                                              }}
                                            >
                                              {myActivityData.AccountName
                                                ? myActivityData.AccountName.replace("#", " ")
                                                : ""}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xs={3} className="marginbottom-10px text-end">
                                          <div className="d-flex flex-direction-row align-items-center justify-content-end">
                                            <div className="reports-icons me-2">
                                              <img src={ReportCalendar} alt="" />
                                            </div>
                                            <div className="report-content-one">
                                              {/* {myActivityData.StartDate.includes("T")
                                                ? myActivityData.StartDate.split("T")[0]
                                                : myActivityData.StartDate}{" "}
                                              -{" "}
                                              {myActivityData.EndDate.includes("T")
                                                ? myActivityData.EndDate.split("T")[0]
                                                : myActivityData.EndDate} */}
                                              {myActivityData.StartDate !== undefined &&
                                              myActivityData.StartDate !== null
                                                ? myActivityData.StartDate.includes("T")
                                                  ? moment(
                                                      myActivityData.StartDate.split("T")[0]
                                                    ).format("MM/DD/YYYY")
                                                  : !myActivityData.StartDate.includes("T")
                                                  ? moment(myActivityData.StartDate).format(
                                                      "MM/DD/YYYY"
                                                    )
                                                  : "-"
                                                : "-"}
                                              -{" "}
                                              {myActivityData.EndDate !== undefined &&
                                              myActivityData.EndDate !== null
                                                ? myActivityData.EndDate.includes("T")
                                                  ? moment(
                                                      myActivityData.EndDate.split("T")[0]
                                                    ).format("MM/DD/YYYY")
                                                  : !myActivityData.EndDate.includes("T")
                                                  ? moment(myActivityData.EndDate).format(
                                                      "MM/DD/YYYY"
                                                    )
                                                  : "-"
                                                : "-"}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col xs={3} className="marginbottom-10px">
                                          <div className="d-flex flex-direction-row align-items-center">
                                            <div className="reports-icons me-2">
                                              <img src={ReportUserCall} alt="" />
                                            </div>
                                            <div className="report-content-one">
                                              {myActivityData.ContactName}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xs={3} className="marginbottom-10px">
                                          <div className="d-flex flex-direction-row align-items-center">
                                            <div className="reports-icons me-2">
                                              <img src={ReportNormal} alt="" />
                                            </div>
                                            <div className="report-content-one">
                                              {myActivityData.Priority_Term}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xs={3} className="marginbottom-10px">
                                          <div className="d-flex flex-direction-row align-items-center">
                                            <div className="reports-icons me-2">
                                              <img src={ReportEmail} alt="" />
                                            </div>
                                            <div className="report-content-one">
                                              {myActivityData.ActivityType_Term}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xs={3} className="marginbottom-10px">
                                          <div className="d-flex flex-direction-row align-items-center">
                                            <div className="reports-icons me-2">
                                              <img src={ReportAccounts} alt="" />
                                            </div>
                                            <div className="report-content-one">
                                              {myActivityData.AssociationType_Term}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row></Row>

                                      <Row>
                                        <Col xs={12}>
                                          {/* <div className="d-flex flex-direction-row align-items-start"> */}
                                          {/* <div className="d-flex"> */}
                                          <div className="reports-icons me-2">
                                            <img src={ReportNots} alt="" />
                                          </div>
                                          <div className="report-content-one">
                                            <div
                                              className="activity_detail_div_html"
                                              dangerouslySetInnerHTML={{
                                                __html: myActivityData.ActivityDetails
                                                  ? myActivityData.ActivityDetails
                                                  : "-",
                                              }}
                                            />
                                          </div>
                                          {/* </div> */}
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </>
                            ) : (
                              ""
                            )
                          )}
                      </>
                    ))}
                </Row>
                <Row></Row>
              </Col>
            </div>
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col lg={12}>
            {/* <div className="table-title">Focus Account & Leads Upcoming 7 Days</div> */}
            <div className="table-title">Next Week Focus Account & Leads </div>
            <div className="col-title-bar marginbottom-10px">Account Name</div>
            <div className="col-box-bar mb-3">
              {item.item.lstFocusUpcomingAccountData !== undefined &&
              item.item.lstFocusUpcomingAccountData !== null &&
              item.item.lstFocusUpcomingAccountData.length > 0
                ? item.item.lstFocusUpcomingAccountData.map((item: any) => (
                    <>
                      <label
                        className="report-label"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (page_rights.is_view) {
                            navigate("/accounts-view", {
                              state: { AccountID: item.AccountID, AccountName: item.AccountName },
                            });
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                        onKeyDown={() => {
                          if (page_rights.is_view) {
                            navigate("/accounts-view", {
                              state: { AccountID: item.AccountID, AccountName: item.AccountName },
                            });
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                      >
                        {item.AccountName ? item.AccountName.replace("#", " ") : ""}
                      </label>
                    </>
                  ))
                : "No records found"}
            </div>
          </Col>
        </Row>

        {item.handleActiviityData.isIncludeActivity === true ? (
          <Row>
            {item.item.lstUpcomingActivityDataCount.length ? (
              <>
                <div className="table-title">
                  <h4 className="mt-5" style={{ fontWeight: "600" }}>
                    Next Week Activities
                    {`(${
                      item.item.dateData.NextWeekStartDate +
                      " - " +
                      item.item.dateData.NextWeekEndDate
                    })`}
                  </h4>
                  <div className="d-flex">
                    <h6>Total Open Activities &nbsp; {":"} &nbsp; </h6>
                    {item.item.lstUpcomingActivityDataCount.length &&
                      item.item.lstUpcomingActivityDataCount.map((itm) => (
                        <h6 className="mb-2">
                          {" "}
                          {itm.ActivityType_Term} {"(" + itm.CompletedCount + ") " + ","}
                        </h6>
                      ))}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <Col md={12}>
              <Row>
                {item.item.lstUpcomingActivityDataCount &&
                  item.item.lstUpcomingActivityDataCount.map((myCount) => (
                    <>
                      <div className="col-title-bar marginbottom-10px">
                        {myCount.ActivityType_Term} : {myCount.CompletedCount}
                      </div>

                      {item.item.lstUpcomingActivityData &&
                        item.item.lstUpcomingActivityData.map((myActivityData) =>
                          myActivityData.ActivityType_Term === myCount.ActivityType_Term ? (
                            <>
                              <Col lg={12} xl={12} className="mb-3">
                                <Card className="border-0 h-100">
                                  <Card.Body>
                                    <Row className="marginbottom-10px">
                                      <Col xs={9}>
                                        <div className="d-flex flex-direction-row align-items-start">
                                          <div className="reports-icons me-2">
                                            <img src={HardRet} alt="" />
                                          </div>
                                          <div className="report-card-title">
                                            {myActivityData.ActivitySubject}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={3}
                                        className="d-flex justify-content-end align-items-start"
                                      >
                                        <label className="danger-label">
                                          {myActivityData.ActivityStatus_Term}
                                        </label>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={9} className="marginbottom-10px">
                                        <div className="d-flex flex-direction-row align-items-center">
                                          <div className="reports-icons me-2">
                                            <img src={ReportUser} alt="" />
                                          </div>
                                          <div
                                            className="report-content-one report-user-content"
                                            role="button"
                                            tabIndex={-1}
                                            style={{ cursor: "pointer", color: "blue" }}
                                            onClick={() => {
                                              handleNavigate(myActivityData);
                                            }}
                                            onKeyDown={() => {
                                              handleNavigate(myActivityData);
                                            }}
                                          >
                                            {myActivityData.AccountName
                                              ? myActivityData.AccountName.replace("#", " ")
                                              : ""}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col xs={3} className="marginbottom-10px">
                                        <div className="d-flex flex-direction-row align-items-center justify-content-end">
                                          <div className="reports-icons me-2">
                                            <img src={ReportCalendar} alt="" />
                                          </div>
                                          <div className="report-content-one">
                                            {myActivityData.StartDate !== undefined &&
                                            myActivityData.StartDate !== null
                                              ? myActivityData.StartDate.includes("T")
                                                ? moment(
                                                    myActivityData.StartDate.split("T")[0]
                                                  ).format("MM/DD/YYYY")
                                                : !myActivityData.StartDate.includes("T")
                                                ? moment(myActivityData.StartDate).format(
                                                    "MM/DD/YYYY"
                                                  )
                                                : "-"
                                              : "-"}{" "}
                                            -{" "}
                                            {myActivityData.EndDate !== undefined &&
                                            myActivityData.EndDate !== null
                                              ? myActivityData.EndDate.includes("T")
                                                ? moment(
                                                    myActivityData.EndDate.split("T")[0]
                                                  ).format("MM/DD/YYYY")
                                                : !myActivityData.EndDate.includes("T")
                                                ? moment(myActivityData.EndDate).format(
                                                    "MM/DD/YYYY"
                                                  )
                                                : "-"
                                              : "-"}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={3} className="marginbottom-10px">
                                        <div className="d-flex flex-direction-row align-items-center">
                                          <div className="reports-icons me-2">
                                            <img src={ReportUserCall} alt="" />
                                          </div>
                                          <div className="report-content-one">
                                            {myActivityData.ContactName}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col xs={3} className="marginbottom-10px">
                                        <div className="d-flex flex-direction-row align-items-center">
                                          <div className="reports-icons me-2">
                                            <img src={ReportNormal} alt="" />
                                          </div>
                                          <div className="report-content-one">
                                            {myActivityData.Priority_Term}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col xs={3} className="marginbottom-10px">
                                        <div className="d-flex flex-direction-row align-items-center">
                                          <div className="reports-icons me-2">
                                            <img src={ReportEmail} alt="" />
                                          </div>
                                          <div className="report-content-one">
                                            {myActivityData.ActivityType_Term}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col xs={3} className="marginbottom-10px">
                                        <div className="d-flex flex-direction-row align-items-center">
                                          <div className="reports-icons me-2">
                                            <img src={ReportAccounts} alt="" />
                                          </div>
                                          <div className="report-content-one">
                                            {myActivityData.AssociationType_Term}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row></Row>
                                    <Row>
                                      <Col xs={12}>
                                        {/* <div className="d-flex flex-direction-row align-items-start"> */}
                                        {/* <div className="d-flex flex-direction-row align-items-start"> */}
                                        <div className="reports-icons me-2">
                                          <img src={ReportNots} alt="" />
                                        </div>
                                        <div className="report-content-one">
                                          <div
                                            className="activity_detail_div_html"
                                            dangerouslySetInnerHTML={{
                                              __html: myActivityData.ActivityDetails
                                                ? myActivityData.ActivityDetails
                                                : "-",
                                            }}
                                          />
                                          {/* </div> */}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </>
                          ) : (
                            ""
                          )
                        )}
                    </>
                  ))}
              </Row>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
