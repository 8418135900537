const cryptoKey = "SqT_InntelligentCRM_kamlesh_393987";
const CryptoJS = require("crypto-js");
const dataValues = localStorage.getItem("accessToken") || "";
let dataStr = "";
if (dataValues !== "") {
  var bytes: any;
  bytes = CryptoJS.AES.decrypt(dataValues, cryptoKey);
  dataStr = bytes.toString(CryptoJS.enc.Utf8);
}

let authUser = '{}'
let localAuthUser = localStorage.getItem("authUser");
if (localAuthUser) {
  var authBytes: any;
  authBytes = CryptoJS.AES.decrypt(localAuthUser, cryptoKey);
  authUser = authBytes.toString(CryptoJS.enc.Utf8);
}

// All Clients decriptions:
let allClients = '[]'

let localAllClients = localStorage.getItem("allClients");
if (localAllClients) {
  var authBytes: any;
  authBytes = CryptoJS.AES.decrypt(localAllClients, cryptoKey);
  allClients = authBytes.toString(CryptoJS.enc.Utf8);
}

export const sessionInitState = {
  is_logged: localStorage.getItem("accessToken") ? true : false,
  auth_UserDetail: JSON.parse(authUser),
  access_Token: dataStr,
  sidemenu_rights: localStorage.getItem("sidemenu_rights")
    ? JSON.parse(localStorage.getItem("sidemenu_rights") || "{}")
    : null,
  user_rights: localStorage.getItem("user_rights")
    ? JSON.parse(localStorage.getItem("user_rights") || "{}")
    : null,
  lead_data_reload_count: 0,
  account_data_reload_count: 0,
  contact_data_reload_count: 0,
  activity_data_reload_count: 0,
};
export const headerInitState = {
  Hotels: {
    projectTerm: [],
    propertyDetail: [],
  },
  Updated_Logo: "",
};

var currentTime = new Date();
var presentYear = "";
presentYear = currentTime.getFullYear().toString();

export const dashboardInitState = {
  Dashboard: {
    chartTable: [],
    gmailDetails: [],
    goalswithSuspect: [],
    top20Accounts: [],
  },
  SelectedPropertyID: [],
  SelectedLogo: "",
  SelectedPropertyName: "",
  SelectedPortfolio: "",
  SelectedPortfolioID: "",
  isLogochangeData: "",
  Year: presentYear,
  SelectedAccount: [],
  GlobalHotels: [],
  all_clients: JSON.parse(allClients),
};
