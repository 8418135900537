/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import Select from "react-select";
import GoalReport from "../../../Asset/Images/Other/generate-report.png";
import PageLoader from "../../../Component/Loader/PageLoader";
import {
  AppConfigData,
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
} from "../../../Services/CommonServices";
import { getEmailAssignToDropdownList } from "../../../Services/LeftMenuServices";
import { GetHotelsListForReports } from "../../../Services/Setup&Config/HotelService";
import Unauthorized from "../../Authentication/Unauthorized";
import ReportNavigations from "../ReportNavigations";
import { MultiPropertyWeeklyReportSingleFileExportPDF } from "../../../Services/ReportServices";
import PdfIcon from "../../../Asset/Images/Icons/PdfIcon.svg";
import MultiPropertyWeeklyReportsModal from "../../../Model/Reports/MultiPropertyWeeklyReportsModal";

const MultiPropertyWeeklyReport = () => {
  let MultiPropertyWeeklyReportReport_page_rights = CommonService.getPageRight("WeeklyReport");

  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const [showReport, setShowReport]: any = useState(false);
  const [loadProcessPDF, setloadProcessPDF] = useState(false);
  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  let iniInputData = {
    propertyIDs: "",
    userIDs: "",
    startDate: "",
    endDate: "",
    isIncludeActivity: false,
  };
  const [inputs, setInputs] = useState(iniInputData);
  const [inputsEr, setInputsEr] = useState(iniInputData);
  const [selHotels, setSelHotels]: any = useState([]);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  let [PropertiesListData, setPropertiesListData]: any = useState({
    IsData: true,
    data: [],
  });
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );

  const [ReportInput, setReportInput]: any = useState({});

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("MultiplePropertyWeeklyReportFilter"))) {
      HandleRemoveOtherFilters("MultiplePropertyWeeklyReportFilter");
      HandleFilters("MultiplePropertyWeeklyReportFilter", {
        pagePath: "/multiple-property-weekly-report",
        pageName: "MultiplePropertyWeeklyReport",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  useEffect(() => {
    if (SelectedPropertyName) {
      if (!SelectedPropertyID.includes(",")) {
        setSelHotels([
          {
            value: SelectedPropertyID,
            label: SelectedPropertyName,
          },
        ]);
        setInputs({
          ...inputs,
          propertyIDs: SelectedPropertyID,
        });
        sethotelChangeLoading(true);
        fetchUsersList(SelectedPropertyID);
      }
    }

    getHotelsList();
  }, [SelectedPropertyName]);

  let HotelsDropdown: any = [{ label: "All Hotels", value: "" }];

  PropertiesListData.data !== undefined &&
    PropertiesListData.data !== null &&
    PropertiesListData.data.length > 0 &&
    PropertiesListData.data.map((item: any) => {
      HotelsDropdown.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      userID: userId,
    };
    setPropertiesListData({ IsData: true, data: [] });
    let res: any = await GetHotelsListForReports(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setPropertiesListData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPropertiesListData({ IsData: false, data: [] });
        }
      } else {
        setPropertiesListData({ IsData: false, data: [] });
      }
    } else {
      setPropertiesListData({ IsData: false, data: [] });
    }
  }

  const validateForm = () => {
    let is_validate = true;
    let error_json: any = {};
    Object.keys(inputsEr).forEach((er_key) => {
      error_json = {
        ...error_json,
        [er_key]: "",
      };
    });
    Object.keys(error_json).forEach((er_key) => {
      if (inputs[er_key] === "") {
        is_validate = false;
        error_json = {
          ...error_json,
          [er_key]: "This field is required.",
        };
      }
    });
    setInputsEr(error_json);
    return is_validate;
  };
  const handleGenerateReport = async () => {
    if (validateForm()) {
      setReportInput(inputs);
      setShowReport(true);
    }
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  function handleDateRange(e) {
    let [startDate, endDate] = e;
    if (startDate !== null && endDate !== null) {
      let x = moment(startDate).format("MM-DD-YYYY");
      let y = moment(endDate).format("MM-DD-YYYY");

      setInputs({
        ...inputs,
        ["startDate"]: x,
        ["endDate"]: y,
      });
    } else {
      setInputs({
        ...inputs,
        ["startDate"]: "",
        ["endDate"]: "",
      });
    }
  }

  async function SelectedPropertyData(data: any) {
    sethotelChangeLoading(true);
    let temp: any = [];
    if (data.length) {
      data.map((item: any) => {
        temp.push(item.value);
      });

      // setInputs({
      //   ...inputs,
      //   propertyIDs: temp.toString(),
      // });
      fetchUsersList(temp.toString());
    } else {
      setInputs({
        ...inputs,
        propertyIDs: "",
        userIDs: "",
      });
      setUsersList([]);
      setSelectedUsers([]);
      sethotelChangeLoading(false);
    }
  }

  const fetchUsersList = async (data_ids) => {
    let input = {
      clientID: clientID,
      propertyIDs: data_ids,
    };
    let res: any = await getEmailAssignToDropdownList(input, Token);
    if (res.data.success) {
      if (res.data.data !== null && res.data.data !== undefined && res.data.data !== "") {
        let userListDataToAppend: any = [
          {
            label: "All Users",
            value: "All Users",
          },
        ];
        res.data.data.dataList1.forEach((res_data) => {
          userListDataToAppend = [
            ...userListDataToAppend,
            {
              label: res_data.UserName,
              value: res_data.UserID,
            },
          ];
        });
        setUsersList(userListDataToAppend);

        // Logic for selected user by property.
        if (selectedUsers.length) {
          let resultedUsers: any = [];
          selectedUsers.forEach((element: any) => {
            if (res.data.data.dataList1.length) {
              res.data.data.dataList1.forEach((data: any) => {
                if (data.UserID === element.value) {
                  resultedUsers.push({ label: element.label, value: element.value });
                }
              });
            }
          });
          setSelectedUsers(resultedUsers);
          let fiteredUsers: any = [];
          resultedUsers.forEach((obj) => {
            fiteredUsers.push(obj.value);
          });

          setInputs({
            ...inputs,
            propertyIDs: data_ids,
            userIDs: fiteredUsers.toString(),
          });
        } else {
          setInputs({
            ...inputs,
            propertyIDs: data_ids,
            // userIDs: fiteredUsers.toString(),
          });
        }
      } else {
        setUsersList([]);
      }
    } else {
      setUsersList([]);
    }
    sethotelChangeLoading(false);
  };

  async function exportPDF() {
    if (validateForm()) {
      debugger;
      let inputData: any = {
        propertyIDs: inputs.propertyIDs,
        startDate: inputs.startDate,
        endDate: inputs.endDate,
        userIDs: inputs.userIDs,
        isIncludeActivity: inputs.isIncludeActivity,
      };

      setloadProcessPDF(true);
      let res: any = await MultiPropertyWeeklyReportSingleFileExportPDF(inputData, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            window.open(AppConfigData.APIBasePath + res.data.data);
            setloadProcessPDF(false);
          } else {
            ToasterError(res.data.message);
            setloadProcessPDF(false);
          }
        } else {
          ToasterError(res.data.message);
          setloadProcessPDF(false);
        }
      } else {
        ToasterError(res.data.message);
        setloadProcessPDF(false);
      }
    }
  }

  return (
    <>
      <div className="report-page">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={4}>
            <ReportNavigations />
          </Col>
          <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
            {MultiPropertyWeeklyReportReport_page_rights.is_view ? (
              PropertiesListData.IsData === false ? (
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col lg={12} xl={12} className="mb-4">
                      <div className={`${inputsEr.propertyIDs && "red-border-masking"}`}>
                        <FloatingLabel label="">
                          <Select
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            }}
                            placeholder="Select Property"
                            options={HotelsDropdown}
                            isMulti
                            value={selHotels}
                            defaultValue={HotelsDropdown.filter(
                              (itm) => inputs.propertyIDs && itm.value.includes(inputs.propertyIDs)
                            )}
                            onChange={(event: any) => {
                              if (event.length) {
                                let data = event.filter((itm) => itm.label === "All Hotels");
                                HotelsDropdown.splice(data, 1);
                                if (data.length) {
                                  SelectedPropertyData(HotelsDropdown);
                                  setSelHotels(HotelsDropdown);
                                } else {
                                  setSelHotels(event);
                                  SelectedPropertyData(event);
                                }
                              } else {
                                setSelHotels([]);
                                SelectedPropertyData([]);
                              }
                            }}
                          ></Select>
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {inputsEr.propertyIDs && inputsEr.propertyIDs}{" "}
                      </small>
                    </Col>
                    <Col lg={12} xl={12} className="mb-3">
                      <div className={`${inputsEr.userIDs && "red-border-masking"}`}>
                        <FloatingLabel controlId="selectUser" label="">
                          <Select
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            }}
                            name="colors"
                            placeholder="Select Users"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isDisabled={false}
                            isMulti={true}
                            options={usersList}
                            value={selectedUsers}
                            onChange={(itm: any) => {
                              let tmpItems = itm;
                              let data = tmpItems.filter((item: any) => item.label === "All Users");
                              // For All user Selection
                              if (data.length) {
                                let userIds = "";
                                let tmpUsersList = [...usersList];
                                tmpUsersList.splice(data, 1);
                                tmpUsersList.forEach((id_user: any) => {
                                  userIds = userIds.length
                                    ? userIds + "," + id_user.value
                                    : id_user.value;
                                });
                                setInputs({
                                  ...inputs,
                                  userIDs: userIds,
                                });
                                setSelectedUsers(tmpUsersList);
                              } else {
                                // For Single - Single Selection
                                let userIds = "";
                                tmpItems.forEach((id_user: any) => {
                                  userIds = userIds.length
                                    ? userIds + "," + id_user.value
                                    : id_user.value;
                                });
                                setInputs({
                                  ...inputs,
                                  userIDs: userIds,
                                });
                                setSelectedUsers(tmpItems);
                              }
                            }}
                          />
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {inputsEr.userIDs && inputsEr.userIDs}{" "}
                      </small>
                    </Col>

                    <Col lg={6} xl={6} className="mb-4">
                      <div className={`${inputsEr.startDate && "red-border-masking"}`}>
                        <div className="form-floating">
                          <DatePicker
                            className="form-control form-control-date datepicker-react"
                            placeholderText="Select Start & End Date"
                            minDate={new Date("2010/01/01")}
                            maxDate={new Date("9999/12/31")}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                              handleDateRange(update);
                              setDateRange(update);
                            }}
                          />
                        </div>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {inputsEr.startDate && inputsEr.startDate}{" "}
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} xl={6} className="mb-3 d-flex align-items-center">
                      {["checkbox"].map((type: any) => (
                        <div key={`inline-${type}`} className="option-field">
                          <Form.Check
                            inline
                            label="Include Activities"
                            name="group16"
                            type={type}
                            id="Include Activities"
                            onChange={(e: any) =>
                              setInputs({
                                ...inputs,
                                ["isIncludeActivity"]: e.target.checked,
                              })
                            }
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="">
                        <Modal.Footer className="border-0 justify-content-start">
                          {!loading ? (
                            <>
                              <Button
                                type="button"
                                onClick={(e) => {
                                  if (MultiPropertyWeeklyReportReport_page_rights.is_view) {
                                    e.preventDefault();
                                    handleGenerateReport();
                                  } else {
                                    CommonService.unauthorizedAction();
                                  }
                                }}
                                variant="primary ms-0"
                              >
                                <span className="me-2">
                                  <img src={GoalReport} alt="" />
                                </span>
                                Generate Report
                              </Button>
                              <Button
                                variant="primary ms-0"
                                disabled={!loadProcessPDF ? false : true}
                                onClick={(e) => {
                                  if (MultiPropertyWeeklyReportReport_page_rights.is_export) {
                                    e.preventDefault();
                                    exportPDF();
                                  } else {
                                    CommonService.unauthorizedAction();
                                  }
                                }}
                              >
                                <span className="me-2">
                                  <img src={PdfIcon} alt="" />
                                </span>
                                Export PDF
                              </Button>
                            </>
                          ) : (
                            <Button type="button" variant="primary">
                              <Spinner
                                size="sm"
                                className="me-2"
                                animation="border"
                                variant="light"
                              />
                              Loading
                            </Button>
                          )}
                        </Modal.Footer>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
                  <PageLoader />
                </Col>
              )
            ) : (
              <Unauthorized />
            )}
          </Col>
        </Row>
        {showReport === true && (
          <MultiPropertyWeeklyReportsModal
            // ReportInput={ReportInput}
            // show={showReport}
            // onHide={() => setShowReport(false)}
            // showReport={showReport}
            // setShowReport={setShowReport}
            StateValue={ReportInput}
            show={showReport}
            onHide={() => setShowReport(false)}
          />
        )}
      </div>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default MultiPropertyWeeklyReport;
