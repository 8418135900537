import React, { useEffect } from "react";
import { Loader } from "react-feather";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { CommonService } from "../Services/CommonServices";
import AccountsActive from "../Views/Accounts/AccountActive";
import AccountsInactive from "../Views/Accounts/AccountInactive";
// import Accounts from "../Views/Accounts/AccountIndex";
const Accounts = React.lazy(() => import("../Views/Accounts/AccountIndex"));

// import AccountDetails from "../Views/Accounts/DetailView/Index";
const AccountDetails = React.lazy(() => import("../Views/Accounts/DetailView/Index"));

// import ActivityDetailView from "../Views/Activity/DetailView/ActivityDetailView";
const ActivityDetailView = React.lazy(
  () => import("../Views/Activity/DetailView/ActivityDetailView")
);

// import ActivityListing from "../Views/Activity/Index";
const ActivityListing = React.lazy(() => import("../Views/Activity/Index"));

import Unauthorized from "../Views/Authentication/Unauthorized";
import InPageNav from "../Views/Common/InPageNav/Index";
import UserProfile from "../Views/Common/Profile/Index";
// import ContactPage from "../Views/Contact";
const ContactPage = React.lazy(() => import("../Views/Contact"));

// import ContactDetails from "../Views/Contact/DetailView";
const ContactDetails = React.lazy(() => import("../Views/Contact/DetailView"));

const Home = React.lazy(() => import("../Views/Home"));

const OpportunityGroup = React.lazy(() => import("../Views/Opportunity/Group"));
const OpportunityLnr = React.lazy(() => import("../Views/Opportunity/Lnr"));
const OpportunityMeetingCatering = React.lazy(() => import("../Views/Opportunity/MeetingCatering"));
const OpportunityRfp = React.lazy(() => import("../Views/Opportunity/Rfp"));

import ActivityGoalsReport from "../Views/Reports/ActivityGoalsReport/index";
import ActivityReport from "../Views/Reports/ActivityReport";
import DashboardWeeklyReport from "../Views/Reports/DashboardWeeklyReport";
import GroupGridTable from "../Views/Reports/GroupGridTable";
import GroupMonthViewReport from "../Views/Reports/GroupMonthViewReport";
import GroupPaceReport from "../Views/Reports/GroupPaceReport";
import ReportMain from "../Views/Reports/Index";
import LNRGridTable from "../Views/Reports/LNRGridTable";
import LostBusinessGridReport from "../Views/Reports/LostBusinessGridReport";
import MarketingPlanReport from "../Views/Reports/MarketingPlanReport";
import MeetingAndCateringByDate from "../Views/Reports/MeetingAndCateringByDate";
import MeetingCateringGridTable from "../Views/Reports/MeetingCateringGridTable";
import MeetingPaceReport from "../Views/Reports/MeetingPaceReport";
import MeetingRoomBookedReport from "../Views/Reports/MeetingRoomBookedReport";
import MonthlyPortfolioReport from "../Views/Reports/MonthlyPortfolioReport";
import OpportunityBySourceReport from "../Views/Reports/OpportunityBySourceReport";
import OpportunitySalesFunnelReport from "../Views/Reports/OpportunitySalesFunnelReport";
import RFPGridTableReport from "../Views/Reports/RFPGridTable";
import WeeklyReports from "../Views/Reports/WeeklyReport";
import Inbox from "../Views/Gmail/Inbox";
import AccountRules from "../Views/SetupAndConfiguration/AccountRules";
import AdditionalSettings from "../Views/SetupAndConfiguration/AdditionalSettings";
import ClientProperties from "../Views/SetupAndConfiguration/ClientProperties";
import CreateNewClients from "../Views/SetupAndConfiguration/CreateNewClients";
import HotelDetails from "../Views/SetupAndConfiguration/HotelDetails/HotelDetails";
import Hotels from "../Views/SetupAndConfiguration/Hotels";
import SetupAndConfigurations from "../Views/SetupAndConfiguration/Index";
import MailSetting from "../Views/SetupAndConfiguration/MailSetting";
import MarketSagmentTypes from "../Views/SetupAndConfiguration/MarketSagmentTypes";
import Portfolios from "../Views/SetupAndConfiguration/Portfolios";
import Users from "../Views/SetupAndConfiguration/UserManagement/Users";
import UsersRole from "../Views/SetupAndConfiguration/UserManagement/UsersRole";
import SupportPage from "../Views/Support/SupportPage";
// import SuspectDetails from "../Views/Suspect/DetailView";
const SuspectDetails = React.lazy(() => import("../Views/Suspect/DetailView"));

// import LeadIndex from "../Views/Suspect/Index";
const LeadIndex = React.lazy(() => import("../Views/Suspect/Index"));

import DefaultHeader from "./DefaultHeader";
import DefaultSidebar from "./DefaultSideBar";
import Not_Found_404 from "../Asset/Images/NotFound/Not_Found_404.png";

import { Col, Container, Row } from "react-bootstrap";

import SupportDetailView from "../Views/Support/SupportDetailView";
import KnowledgeTutorials from "../Views/Knowledge/KnowledgeTutorials";
import NewUserTour from "../Views/Knowledge/NewUserTour";
import AddingNewAccountTour from "../Views/Knowledge/AddingNewAccountTour";
import NewSuspectOrLeadTour from "../Views/Knowledge/NewSuspectOrLeadTour";
import AddingNewContactsTour from "../Views/Knowledge/AddingNewContactsTour";
import OpportunityBuildTour from "../Views/Knowledge/OpportunityBuildTour";
import AllMails from "../Views/Gmail/AllMails";
import ArchiveMails from "../Views/Gmail/ArchiveMails";
import DraftMails from "../Views/Gmail/DraftMails";
import FlaggedMails from "../Views/Gmail/FlaggedMails";
import ImportantMails from "../Views/Gmail/ImportantMails";
import JunkMails from "../Views/Gmail/JunkMails";
import SentMails from "../Views/Gmail/SentMails";
import TrashMails from "../Views/Gmail/TrashMails";
import { Cookies } from "react-cookie-consent";
import Import from "../Views/Import/Import";
import App from "../Views/Microsoft/App";
import Gmail from "../Views/Gmail/Gmail/Gmail";
import LeadReport from "../Views/Reports/LeadReport";
import AccountReport from "../Views/Reports/AccountReport";
import ActualReport from "../Views/Reports/ActualReport";
import MultiPropertyWeeklyReport from "../Views/Reports/MultiPropertyWeeklyReport/MultiPropertyWeeklyReport";
const CryptoJS = require("crypto-js");
const cryptoKey = "SqT_InntelligentCRM_kamlesh_393987";

// const Gmail = React.lazy(() => import("../Views/Gmail/Inbox"));
const TraceReport = React.lazy(() => import("../Views/Reports/TraceReports/index"));
const GeneralSettings = React.lazy(() => import("../Views/SetupAndConfiguration/GeneralSettings"));
const OpportunityDetail = React.lazy(() => import("../Views/Opportunity/DetailView/index"));
const MonthlyReportInput = React.lazy(() => import("../Views/MonthlyReport/MonthlyReport"));
const MonthlyReport = React.lazy(() => import("../Views/Reports/MonthlyReports/index"));
const MarketingPlan = React.lazy(() => import("../Views/MarketingPlan/MarketingPlan"));
const Calendar = React.lazy(() => import("../Views/Miscellaneous/Calendar"));
const OpportunityGRPCalendar = React.lazy(
  () => import("../Views/Miscellaneous/OpportunityGRPCalendar")
);
const MeetCatCalendar = React.lazy(() => import("../Views/Miscellaneous/MeetCatCalendar"));

function DefaultLayout() {
  const dataSelector: any = useSelector((state: any) => state.session);
  const is_logged = dataSelector.is_logged;
  const un = useNavigate();

  const RenderComponent = ({ rightName, Component }) => {
    if (CommonService.getPageRight(rightName).is_view) {
      return (
        <>
          <InPageNav />
          <Component />
        </>
      );
    } else {
      return <Unauthorized />;
    }
  };

  useEffect(() => {
    if (!is_logged) {
      un("/");
    }
  }, []);

  const location: any = useLocation();

  function checkroledata() {
    let enc_NewToken: any = localStorage.getItem("accessToken");
    if (enc_NewToken) {
      // Decrypt
      var bytes = CryptoJS.AES.decrypt(enc_NewToken, cryptoKey);
      var decrypted_NewToken = bytes.toString(CryptoJS.enc.Utf8);

      if (dataSelector.access_Token !== decrypted_NewToken) {
        localStorage.setItem("accessToken", CryptoJS.AES.encrypt(decrypted_NewToken, cryptoKey));
        window.location.reload();
      }
    } else {
      localStorage.clear();
      window.location.reload();
      Cookies.remove("SystemSettingDate");
    }
  }
  useEffect(() => {
    checkroledata();
  }, [location.pathname]);

  return (
    <div>
      {is_logged && (
        <>
          <DefaultHeader />
          <DefaultSidebar />
          <main>
            <Routes>
              <Route
                path="/*"
                element={
                  <>
                    <div className="main-section">
                      <Container fluid={true} className="p-0">
                        <span>
                          <img
                            src={Not_Found_404}
                            style={{ marginTop: "80px" }}
                            width="90%"
                            height="90%"
                            alt=""
                          />
                        </span>
                      </Container>
                    </div>
                  </>
                }
              ></Route>

              {/* Home Route */}
              <Route
                path="/home"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"home"} Component={Home} />
                  </React.Suspense>
                }
              ></Route>

              {/* Lead Route */}
              <Route
                path="/lead"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Lead"} Component={LeadIndex} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/suspect/details"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Lead"} Component={SuspectDetails} />
                  </React.Suspense>
                }
              ></Route>

              {/* Account Routes */}
              <Route
                path="/account"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Account"} Component={Accounts} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/user-profile"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <UserProfile />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/accounts/active"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Account"} Component={AccountsActive} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/accounts-view"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Account"} Component={AccountDetails} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/accounts/inactive"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Account"} Component={AccountsInactive} />
                  </React.Suspense>
                }
              ></Route>

              {/* Contact Routes */}
              <Route
                path="/contact/details"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Contacts"} Component={ContactDetails} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/contact"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Contacts"} Component={ContactPage} />
                  </React.Suspense>
                }
              ></Route>

              {/* Oppo Routes */}
              <Route
                path="/opportunity/rfp"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"RFP"} Component={OpportunityRfp} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/opportunity/lnr"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"LNR"} Component={OpportunityLnr} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/opportunity/group"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Group"} Component={OpportunityGroup} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/opportunity/meetingAndCatering"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent
                      rightName={"MeetingAndCatering"}
                      Component={OpportunityMeetingCatering}
                    />
                  </React.Suspense>
                }
              ></Route>
              {/* Oppo Details Routes */}
              <Route
                path="/Opportunity/RFP/details"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <OpportunityDetail />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/Opportunity/LNR/details"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <OpportunityDetail />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/Opportunity/Group/details"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <OpportunityDetail />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/Opportunity/MeetingAndCatering/details"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <OpportunityDetail />
                  </React.Suspense>
                }
              ></Route>

              {/* Setup Routes */}
              <Route
                path="/SetupAndConfiguration"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <SetupAndConfigurations />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/SetupAndConfigurations/companyProfile"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Company"} Component={CreateNewClients} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/SetupAndConfigurations/createNewClient/clientProperties"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Hotels"} Component={ClientProperties} />
                  </React.Suspense>
                }
              ></Route>
              {/* <Route
                path="/SetupAndConfigurations/mailsetting"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"MailSettings"} Component={MailSetting} />
                  </React.Suspense>
                }
              ></Route> */}
              <Route
                path="/SetupAndConfigurations/accountRules"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"AccountRules"} Component={AccountRules} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/SetupAndConfigurations/marketSegmentType"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent
                      rightName={"MarketSegmentType"}
                      Component={MarketSagmentTypes}
                    />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/SetupAndConfigurations/additionalSettings"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent
                      rightName={"AdditionalSettings"}
                      Component={AdditionalSettings}
                    />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/SetupAndConfigurations/portfolios"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Portfolios"} Component={Portfolios} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/SetupAndConfigurations/hotels"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Hotels"} Component={Hotels} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/SetupAndConfigurations/hotels/hotelDetails/:property_id"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Hotels"} Component={HotelDetails} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/SetupAndConfigurations/user"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"User"} Component={Users} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/SetupAndConfigurations/userRole"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"UserRole"} Component={UsersRole} />
                  </React.Suspense>
                }
              ></Route>

              {/* Activity Routes */}
              <Route
                path="/activityList"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Activities"} Component={ActivityListing} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/activity/details"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Activities"} Component={ActivityDetailView} />
                  </React.Suspense>
                }
              ></Route>

              {/* General Setting Routes */}
              <Route
                path="/SetupAndConfigurations/generalsetting"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"GeneralSettings"} Component={GeneralSettings} />
                  </React.Suspense>
                }
              ></Route>

              {/* Reports Routes */}
              <Route
                path="/Reports"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Reports"} Component={ReportMain} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/MonthlyReport"
                element={
                  //  Monthly Input Report
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <MonthlyReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/MonthlyReportInput"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"MonthlyReport"} Component={MonthlyReportInput} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/WeeklyReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <WeeklyReports />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/multiple-property-weekly-report"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <MultiPropertyWeeklyReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/DashboardWeeklyReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <DashboardWeeklyReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/ActivitiesReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <ActivityReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/RFPReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <RFPGridTableReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/ActivityGoalsReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <ActivityGoalsReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/TraceReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <TraceReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/MonthlyPortfolioReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <MonthlyPortfolioReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/MarketingPlanReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <MarketingPlanReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/LNRGridTable"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <LNRGridTable />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/GroupGridTable"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <GroupGridTable />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/MeetingCateringGridTable"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <MeetingCateringGridTable />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/OpportunitySalesFunnelReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <OpportunitySalesFunnelReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/LostBusinessGridReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <LostBusinessGridReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/GroupMonthViewReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <GroupMonthViewReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/MeeingAndCateringMonthViewReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <MeetingRoomBookedReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/OpportunityBySourceReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <OpportunityBySourceReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/MeetingAndCateringByDate"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <MeetingAndCateringByDate />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/GroupPaceReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <GroupPaceReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/MeetingPaceReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <MeetingPaceReport />
                    {/* <RenderComponent rightName="MeetingandCateringbyDate" Component={MeetingAndCateringByDate} /> */}
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/LeadReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <LeadReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/AccountReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <AccountReport />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/ActualReport"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <InPageNav />
                    <ActualReport />
                  </React.Suspense>
                }
              ></Route>

              {/* Reports Routes End*/}

              {/* Menu Top Marketing Plan */}
              <Route
                path="/MonthlyReportInput"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"MonthlyReport"} Component={MonthlyReportInput} />
                  </React.Suspense>
                }
              ></Route>
              {/* Menu Top Marketing Plan End */}

              {/* Menu Top Monthly Report */}
              <Route
                path="/MarketingPlan"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"MarketingPlan"} Component={MarketingPlan} />
                  </React.Suspense>
                }
              ></Route>
              {/* Menu Top Monthly Report End*/}

              {/* Calender Routes */}
              <Route
                path="/calendar"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Calendar"} Component={Calendar} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/opportunity-group/calendar"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Calendar"} Component={OpportunityGRPCalendar} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/meeting-catering/calendar"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName={"Calendar"} Component={MeetCatCalendar} />
                  </React.Suspense>
                }
              ></Route>

              {/* Support Routes */}
              <Route
                path="/support"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={SupportPage} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/support/details"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={SupportDetailView} />
                  </React.Suspense>
                }
              ></Route>

              {/* Knowledge Routes */}
              <Route
                path="/knowledge-tutorials"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={KnowledgeTutorials} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/new-user-tour"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={NewUserTour} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/adding-new-account-tour"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={AddingNewAccountTour} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/new-suspect-or-lead-tour"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={NewSuspectOrLeadTour} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/adding-new-contacts-tour"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={AddingNewContactsTour} />
                  </React.Suspense>
                }
              ></Route>

              <Route
                path="/opportunity-build-tour"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={OpportunityBuildTour} />
                  </React.Suspense>
                }
              ></Route>

              {/* Old Gmaail Routes Start */}
              {/* <Route
                path="/inbox"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={Inbox} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/all-mails"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={AllMails} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/archive-mails"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={ArchiveMails} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/draft-mails"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={DraftMails} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/starred-mails"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={FlaggedMails} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/important-mails"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={ImportantMails} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/spam-mails"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={JunkMails} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/sent-mails"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={SentMails} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/trash-mails"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={TrashMails} />
                  </React.Suspense>
                }
              ></Route> */}
              {/* Old Gmaail Routes End */}

              {/* Imports Route Start*/}
              <Route
                path="/import"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={Import} />
                  </React.Suspense>
                }
              ></Route>
              {/* Imports Route End*/}

              {/*Main Gmail & Microsoft Routes */}
              <Route
                path="/gmail"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={Gmail} />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path="/microsoft"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <RenderComponent rightName="" Component={App} />
                  </React.Suspense>
                }
              ></Route>
              {/* Main Gmail & Microsoft Routes */}
            </Routes>

            {/* <InPageNav /> */}
            {/* <Outlet /> */}
          </main>
        </>
      )}
    </div>
  );
}

export default DefaultLayout;
