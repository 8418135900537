/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// import { toast } from "react-toastify";
// import DefaultUserLogo from "../../Asset/Images/DefaultLoginIcons/DefaultUserLogo.png";
import ProfileImg from "../../Asset/Images/Header/profile-img.png";
import { ENUMS } from "../../Constants";
import { getAllClients, isLogochangeData, updateUserData } from "../../Redux/actions";
import { SendUserOTPEmail, VerifyClientLogin } from "../../Services/LoginServices";
import { ToasterError } from "../../Services/CommonServices";
import DefaultUserLogo from "../../Asset/Images/AvtarImages/Userlogo.png";
import { useSelector } from "react-redux";

export const ClientSelection = () => {
  const location: any = useLocation();
  const AllClientsList: any = useSelector((state: any) => state?.dashboard?.all_clients);
  const ClientDetail = location?.state?.data ? location?.state?.data : AllClientsList;

  const navigate = useNavigate();
  const cryptoKey = "SqT_InntelligentCRM_kamlesh_393987";
  const CryptoJS: any = require("crypto-js");
  const dispatch = useDispatch();

  async function getClientDetails(item) {
    if (ClientDetail !== null || ClientDetail !== "") {
      if (item !== null || item !== "") {
        let Verifyinput = {
          userID: item.U_UserID,
          dbConnectionID: item.DBConnectionID,
          clientID: item.C_ClientID,
          from: "Client",
        };

        // Logic to set DBCOnnectionID in local with encription :
        localStorage.setItem("loginTime", Date.now());
        localStorage.setItem(
          "DBConnectionID",
          CryptoJS.AES.encrypt(item.DBConnectionID, cryptoKey)
        );
        //This Method to we do a Client Selection for same api

        const resdata: any = await VerifyClientLogin(Verifyinput);
        if (resdata !== "" && resdata.data.data) {
          if (resdata.data.data.dataList1[0]?.IsTSA) {
            let input = {
              keyID:
                resdata.data.data.dataList1[0]?.U_UserID &&
                resdata.data.data.dataList1[0]?.U_UserID,
            };
            let res: any = await SendUserOTPEmail(
              input,
              resdata.data.data.dataList2 && resdata.data.data.dataList2[0]?.accessToken
            );

            if (res.data.success) {
              let initialRights = ENUMS.side_menu;
              let newRights = {};

              Object.keys(initialRights).forEach((key_id) => {
                if (initialRights[key_id].hasChild) {
                  let newChild: any = [];
                  initialRights[key_id].child.forEach((ch_itm) => {
                    let newInnerChild: any = [];

                    if (ch_itm.hasChild) {
                      ch_itm.child.forEach((inner_ch_itm) => {
                        let resDataCheck1 = resdata.data.data.dataList3[0]?.filter(
                          (itm) => itm.RightName == inner_ch_itm.RightName
                        );

                        if (resDataCheck1?.length) {
                          newInnerChild = [
                            ...newInnerChild,
                            {
                              ...inner_ch_itm,
                              is_view: resDataCheck1[0]?.IsView,
                              is_create: resDataCheck1[0]?.IsCreate,
                              is_update: resDataCheck1[0]?.IsEdit,
                              is_delete: resDataCheck1[0]?.IsDelete,
                              is_export: resDataCheck1[0]?.IsExport,
                            },
                          ];
                        } else {
                          newInnerChild = [
                            ...newInnerChild,
                            {
                              ...inner_ch_itm,
                              is_view: false,
                              is_create: false,
                              is_update: false,
                              is_delete: false,
                              is_export: false,
                            },
                          ];
                        }
                      });
                    }
                    let resDataCheck2 = resdata.data.data.dataList3[0]?.filter(
                      (itm) => itm.RightName == ch_itm.RightName
                    );

                    if (ch_itm.hasChild) {
                      newChild = [
                        ...newChild,
                        {
                          ...ch_itm,
                          child: newInnerChild,
                        },
                      ];
                    } else {
                      if (resDataCheck2?.length) {
                        newChild = [
                          ...newChild,
                          {
                            ...ch_itm,
                            is_view: resDataCheck2[0]?.IsView,
                            is_create: resDataCheck2[0]?.IsCreate,
                            is_update: resDataCheck2[0]?.IsEdit,
                            is_delete: resDataCheck2[0]?.IsDelete,
                            is_export: resDataCheck2[0]?.IsExport,
                          },
                        ];
                      } else {
                        newChild = [
                          ...newChild,
                          {
                            ...ch_itm,
                            is_view: false,
                            is_create: false,
                            is_update: false,
                            is_delete: false,
                            is_export: false,
                          },
                        ];
                      }
                    }
                    newRights = {
                      ...newRights,
                      [key_id]: {
                        ...ENUMS.side_menu[key_id],
                        child: newChild,
                      },
                    };
                  });
                } else {
                  let resDataCheck3 = resdata.data.data.dataList3[0]?.filter(
                    (itm) => itm.RightName == initialRights[key_id].RightName
                  );
                  if (resDataCheck3?.length) {
                    newRights = {
                      ...newRights,
                      [key_id]: {
                        ...ENUMS.side_menu[key_id],
                        is_view: resDataCheck3?.length
                          ? resDataCheck3[0]?.IsView
                          : initialRights[key_id].is_view,
                        is_create: resDataCheck3?.length
                          ? resDataCheck3[0]?.IsCreate
                          : initialRights[key_id].is_create,
                        is_update: resDataCheck3?.length
                          ? resDataCheck3[0]?.IsEdit
                          : initialRights[key_id].is_update,
                        is_delete: resDataCheck3?.length
                          ? resDataCheck3[0]?.IsDelete
                          : initialRights[key_id].is_delete,
                        is_export: resDataCheck3?.length
                          ? resDataCheck3[0]?.IsExport
                          : initialRights[key_id].is_export,
                      },
                    };
                  } else {
                    if (initialRights[key_id].RightName === "Dashboard") {
                      newRights = {
                        ...newRights,
                        [key_id]: {
                          ...ENUMS.side_menu[key_id],
                          is_view: true,
                          is_create: true,
                          is_update: true,
                          is_delete: true,
                          is_export: true,
                        },
                      };
                    } else {
                      newRights = {
                        ...newRights,
                        [key_id]: {
                          ...ENUMS.side_menu[key_id],
                          is_view: false,
                          is_create: false,
                          is_update: false,
                          is_delete: false,
                          is_export: false,
                        },
                      };
                    }
                  }
                }
              });

              navigate("/twoStepAuthentication", {
                state: {
                  UserID:
                    resdata.data.data.dataList1[0]?.U_UserID &&
                    resdata.data.data.dataList1[0]?.U_UserID,
                  token: resdata.data.data.dataList2 && resdata.data.data.dataList2[0]?.accessToken,
                  loggedDetail: resdata.data.data,
                },
              });
            } else {
              ToasterError("Something went wrong.");
            }
          } else {
            localStorage.setItem(
              "accessToken",
              CryptoJS.AES.encrypt(
                resdata.data.data.dataList2 && resdata.data.data.dataList2[0]?.accessToken,
                cryptoKey
              )
            );
            localStorage.setItem(
              "authUser",
              CryptoJS.AES.encrypt(JSON.stringify(resdata.data.data.dataList1[0]), cryptoKey)
            );
            localStorage.setItem(
              "tokenExpire",
              resdata.data.data.dataList2 && resdata.data.data.dataList2[0]?.tokenExpiresIn
            );

            let initialRights = ENUMS.side_menu;
            let newRights = {};

            Object.keys(initialRights).forEach((key_id) => {
              if (initialRights[key_id].hasChild) {
                let newChild: any = [];
                initialRights[key_id].child.forEach((ch_itm) => {
                  let newInnerChild: any = [];

                  if (ch_itm.hasChild) {
                    ch_itm.child.forEach((inner_ch_itm) => {
                      let resDataCheck1 = resdata.data.data.dataList3[0]?.filter(
                        (itm) => itm.RightName == inner_ch_itm.RightName
                      );

                      if (resDataCheck1?.length) {
                        newInnerChild = [
                          ...newInnerChild,
                          {
                            ...inner_ch_itm,
                            is_view: resDataCheck1[0]?.IsView,
                            is_create: resDataCheck1[0]?.IsCreate,
                            is_update: resDataCheck1[0]?.IsEdit,
                            is_delete: resDataCheck1[0]?.IsDelete,
                            is_export: resDataCheck1[0]?.IsExport,
                          },
                        ];
                      } else {
                        newInnerChild = [
                          ...newInnerChild,
                          {
                            ...inner_ch_itm,
                            is_view: false,
                            is_create: false,
                            is_update: false,
                            is_delete: false,
                            is_export: false,
                          },
                        ];
                      }
                    });
                  }
                  let resDataCheck2 = resdata.data.data.dataList3[0]?.filter(
                    (itm) => itm.RightName == ch_itm.RightName
                  );

                  if (ch_itm.hasChild) {
                    newChild = [
                      ...newChild,
                      {
                        ...ch_itm,
                        child: newInnerChild,
                      },
                    ];
                  } else {
                    if (resDataCheck2?.length) {
                      newChild = [
                        ...newChild,
                        {
                          ...ch_itm,
                          is_view: resDataCheck2[0]?.IsView,
                          is_create: resDataCheck2[0]?.IsCreate,
                          is_update: resDataCheck2[0]?.IsEdit,
                          is_delete: resDataCheck2[0]?.IsDelete,
                          is_export: resDataCheck2[0]?.IsExport,
                        },
                      ];
                    } else {
                      newChild = [
                        ...newChild,
                        {
                          ...ch_itm,
                          is_view: false,
                          is_create: false,
                          is_update: false,
                          is_delete: false,
                          is_export: false,
                        },
                      ];
                    }
                  }
                  newRights = {
                    ...newRights,
                    [key_id]: {
                      ...ENUMS.side_menu[key_id],
                      child: newChild,
                    },
                  };
                });
              } else {
                let resDataCheck3 = resdata.data.data.dataList3[0]?.filter(
                  (itm) => itm.RightName == initialRights[key_id].RightName
                );
                if (resDataCheck3?.length) {
                  newRights = {
                    ...newRights,
                    [key_id]: {
                      ...ENUMS.side_menu[key_id],
                      is_view: resDataCheck3?.length
                        ? resDataCheck3[0]?.IsView
                        : initialRights[key_id].is_view,
                      is_create: resDataCheck3?.length
                        ? resDataCheck3[0]?.IsCreate
                        : initialRights[key_id].is_create,
                      is_update: resDataCheck3?.length
                        ? resDataCheck3[0]?.IsEdit
                        : initialRights[key_id].is_update,
                      is_delete: resDataCheck3?.length
                        ? resDataCheck3[0]?.IsDelete
                        : initialRights[key_id].is_delete,
                      is_export: resDataCheck3?.length
                        ? resDataCheck3[0]?.IsExport
                        : initialRights[key_id].is_export,
                    },
                  };
                } else {
                  if (initialRights[key_id].RightName === "Dashboard") {
                    newRights = {
                      ...newRights,
                      [key_id]: {
                        ...ENUMS.side_menu[key_id],
                        is_view: true,
                        is_create: true,
                        is_update: true,
                        is_delete: true,
                        is_export: true,
                      },
                    };
                  } else {
                    newRights = {
                      ...newRights,
                      [key_id]: {
                        ...ENUMS.side_menu[key_id],
                        is_view: false,
                        is_create: false,
                        is_update: false,
                        is_delete: false,
                        is_export: false,
                      },
                    };
                  }
                }
              }
            });
            localStorage.setItem("sidemenu_rights", JSON.stringify(newRights));
            localStorage.setItem("user_rights", JSON.stringify(resdata.data.data.dataList3[0]));
            localStorage.setItem(
              "AppLogo",
              resdata.data.data.dataList2 && resdata.data.data.dataList2[0]?.logo
            );
            dispatch(
              isLogochangeData(resdata.data.data.dataList2 && resdata.data.data.dataList2[0]?.logo)
            );
            dispatch(
              updateUserData(
                resdata.data.data,
                resdata.data.data.dataList1[0],
                resdata.data.data.dataList3[0],
                newRights
              )
            );
            navigate("/home");
          }
        } else {
          ToasterError("Something went wrong.");
        }
      }
    }
  }
  return (
    <div className="login-section">
      <div className="login-title">Select Account</div>
      <div className="client-selection-area">
        {ClientDetail !== undefined &&
          ClientDetail !== null &&
          ClientDetail.length !== 0 &&
          ClientDetail.map((item, index) => (
            <>
              <div
                className="client-selection text-center"
                role="button"
                tabIndex={-1}
                key={index}
                onClick={() => {
                  dispatch(getAllClients(ClientDetail));
                  getClientDetails(item);
                }}
              >
                {
                  <React.Suspense fallback={ProfileImg}>
                    <img
                      src={item.U_DisplayAvtar ? item.U_DisplayAvtar : DefaultUserLogo}
                      style={{
                        maxHeight: "100px",
                        maxWidth: "100px",
                        minHeight: "100px",
                        minWidth: "100px",
                        borderRadius: "50%",
                      }}
                      alt=""
                    />
                  </React.Suspense>
                }

                <span>{item.C_CompanyName}</span>
              </div>
            </>
          ))}
      </div>
    </div>
  );
};
