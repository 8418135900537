import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { Button, Modal, Row, Table, Col, Container } from "react-bootstrap";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import moment from "moment";
import XLSX from "sheetjs-style";
import NoDataFoundImage from "../../Component/NoData/NoDataFoundImage";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  InteractionItem,
} from "chart.js";
import { Bar, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } from "react-chartjs-2";
import {
  ActivityGoalReportExportPDF,
  getActivityGoalsReportList,
} from "../../Services/ReportServices";
import { useSelector } from "react-redux";
import PageLoader from "../../Component/Loader/PageLoader";
import noRecordFound from "../../Asset/Images/Other/no-record-found.svg";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";
import { toast } from "react-toastify";
import { AppConfigData, ToasterError } from "../../Services/CommonServices";

const GenerateActivityGoalsReports = ({ ReportInput, showReport, setShowReport }) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [loading, setLoading] = useState(false);
  const [ReportData, setReportData]: any = useState([]);
  const [Propertynames, setPropertynames]: any = useState({});
  const [loadProcessPDF, setloadProcessPDF] = useState(false);

  useEffect(() => {
    fetchReportData();
  }, []);

  async function fetchReportData() {
    setLoading(true);
    let res: any = await getActivityGoalsReportList(ReportInput, Token);
    if (res.data.success) {
      if (res.data.data !== "undefined" && res.data.data) {
        setReportData(res.data.data.lstReportData);

        setPropertynames(res.data.data !== undefined && res.data.data ? res.data.data : "");
        setLoading(false);
        setShowReport(true);
      } else {
        setShowReport(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  const handleClosePopup = () => {
    setShowReport(false);
  };

  // import faker from 'faker';

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  // const options = {
  //   plugins: {
  //     title: {
  //       display: true,
  //       // text: Propertynames && Propertynames.propertyNames ,
  //     },
  //   },
  //   responsive: true,
  //   scales: {
  //     x: {
  //       innerHeight: "50%",
  //       stacked: true,
  //     },
  //     y: {
  //       innerHeight: "50%",
  //       stacked: true,
  //     },
  //   },
  // };

  // const dynamiclabels: any = [],
  //   datacompletedhours: any = [],
  //   dataremaininghours: any = [],
  //   dataextrahours: any = [];
  // ReportData.length &&
  //   ReportData.map((item, index) => {
  //     dynamiclabels.push(item.UserName);
  //     datacompletedhours.push(item.BlitzCompletedHours);
  //     dataremaininghours.push(item.BlitzRemainingHours);
  //     dataextrahours.push(item.BlitzExtraHours);
  //   });
  // ReportData.map((item, index) => {
  //   dynamiclabels.push(item.UserName);
  //   datacompletedhours.push(item.ClientVisitCompletedHours);
  //   dataremaininghours.push(item.ClientVisitRemainingHours);
  //   dataextrahours.push(item.ClientVisitExtraHours);
  // });
  // ReportData.map((item, index) => {
  //   dynamiclabels.push(item.UserName);
  //   datacompletedhours.push(item.NetworkingCompletedHours);
  //   dataremaininghours.push(item.NetworkingRemainingHours);
  //   dataextrahours.push(item.NetworkingExtraHours);
  // });
  // ReportData.map((item, index) => {
  //   dynamiclabels.push(item.UserName);
  //   datacompletedhours.push(item.SiteVisitCompletedHours);
  //   dataremaininghours.push(item.SiteVisitRemainingHours);
  //   dataextrahours.push(item.SiteVisitExtraHours);
  // });
  // ReportData.map((item, index) => {
  //   dynamiclabels.push(item.UserName);
  //   datacompletedhours.push(item.TelemarketingCompletedHours);
  //   dataremaininghours.push(item.TelemarketingRemainingHours);
  //   dataextrahours.push(item.TelemarketingExtraHours);
  // });
  // const labels = dynamiclabels;
  // const data = {
  //   labels: labels,
  //   datasets: [
  //     {
  //       label: "Completed Goals/Hours",
  //       data: datacompletedhours,
  //       backgroundColor: "skyblue",
  //     },
  //     {
  //       label: "Remaining Goals/Hours",
  //       data: dataremaininghours,
  //       backgroundColor: "#F26062",
  //     },
  //     {
  //       label: "Extra Goals/Hours",
  //       data: dataextrahours,
  //       backgroundColor: "#96DD4F",
  //     },
  //   ],
  // };

  //#region Bar Click

  const printDatasetAtEvent = (dataset: InteractionItem[]) => {
    if (!dataset.length) return;

    const datasetIndex = dataset[0].datasetIndex;
  };

  const printElementAtEvent = (element: InteractionItem[]) => {
    if (!element.length) return;

    let { datasetIndex, index } = element[0];
    let barName: any = labels && labels[index];
    // let y: any = barData.datasets[datasetIndex].data[index];
    let tempArray: any = [];

    ReportData.lstActivityData !== undefined &&
      ReportData.lstActivityData &&
      ReportData.lstActivityData.length &&
      ReportData.lstActivityData.map((item, index) => {
        tempArray.push({
          AccountID: item.AccountID,
          AccountName: item.AccountName.split("#")[0],
          ActivityDetails: item.ActivityDetails,
          ActivityStatus_Term: item.ActivityStatus_Term,
          ActivitySubject: item.ActivitySubject,
          ActivityType_Term: item.ActivityType_Term,
          EndDate: item.EndDate,
          Priority_Term: item.Priority_Term,
          StartDate: item.StartDate,
        });

        // tempArray.push(item.AccountName.split("#")[0]);
      });

    tempArray;

    SelectedBarActivities(tempArray, barName);
  };

  const printElementsAtEvent = (elements: InteractionItem[]) => {
    if (!elements.length) return;
  };

  const chartRef: any = useRef<ChartJS>(null);

  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }
    printDatasetAtEvent(getDatasetAtEvent(chart, event));
    printElementAtEvent(getElementAtEvent(chart, event));
    printElementsAtEvent(getElementsAtEvent(chart, event));
  };

  const [filteredActivityData, setfilteredActivityData]: any = useState({ name: "", data: [] });
  let SelectedBarName: any = "";

  function SelectedBarActivities(array, barName) {
    SelectedBarName = barName;
    let tempArray: any = [];
    array.map((item, index) => {
      if (item.AccountName === barName) {
        tempArray.push(item);
      }
    });
    setfilteredActivityData({ name: barName, data: tempArray });
  }

  //#endregion

  async function exportActivityGoalReportPDF() {
    setloadProcessPDF(true);
    const res: any = await ActivityGoalReportExportPDF(ReportInput, Token);
    setloadProcessPDF(false);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          window.open(AppConfigData.APIBasePath + res.data.data);
        } else {
          ToasterError("No Record Found");
        }
      } else {
        ToasterError("Something Went Wrong.");
      }
    } else {
      ToasterError("Something Went Wrong.");
    }
  }

  const labels = ["Blitz", "ClientVisit", "Networking", "SiteVisit", "Telemarketing"];

  function handleBarsData(item) {
    let datasets = [
      {
        label: "Completed goals",
        data: [
          item.BlitzCompletedHours,
          item.ClientVisitCompletedHours,
          item.NetworkingCompletedHours,
          item.SiteVisitCompletedHours,
          item.TelemarketingCompletedHours,
        ],
        backgroundColor: "#cc0000",
      },
      {
        label: "Extra goals",
        data: [
          item.BlitzExtraHours,
          item.ClientVisitExtraHours,
          item.NetworkingExtraHours,
          item.SiteVisitExtraHours,
          item.TelemarketingExtraHours,
        ],
        backgroundColor: "green",
      },
      {
        label: "Remaining goals",
        data: [
          item.ClientVisitRemainingHours,
          item.BlitzRemainingHours,
          item.SiteVisitRemainingHours,
          item.TelemarketingRemainingHours,
          item.NetworkingRemainingHours,
        ],
        backgroundColor: "#94b03f",
      },
    ];
    return datasets;
  }

  function handleHideShowChart(item) {
    let tmpTotal = 0;
    tmpTotal =
      item.BlitzCompletedHours +
      item.BlitzExtraHours +
      item.BlitzRemainingHours +
      item.BlitzTarget +
      item.ClientVisitCompletedHours +
      item.ClientVisitExtraHours +
      item.ClientVisitRemainingHours +
      item.ClientVisitTarget +
      item.NetworkingCompletedHours +
      item.NetworkingExtraHours +
      item.NetworkingRemainingHours +
      item.NetworkingTarget +
      item.SiteVisitCompletedHours +
      item.SiteVisitExtraHours +
      item.SiteVisitRemainingHours +
      item.SiteVisitTarget +
      item.TelemarketingCompletedHours +
      item.TelemarketingExtraHours +
      item.TelemarketingRemainingHours +
      item.TelemarketingTarget;

    if (tmpTotal > 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Modal
      show={showReport}
      onHide={() => {
        handleClosePopup();
      }}
      fullscreen
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Activity Goal Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loading ? (
          ReportData.length ? (
            <>
              <div>{ReportData.propertyNames}</div>
              <Container fluid>
                <Row>
                  {ReportData.length > 0 &&
                    ReportData.map((itm: any, idx) => (
                      <>
                        {handleHideShowChart(itm) === true && (
                          <Col className="mt-3" key={idx} md={4}>
                            <div>
                              {/* <Bar ref={chartRef} onClick={onClick} options={options} data={data} /> */}

                              <Bar
                                ref={chartRef}
                                onClick={onClick}
                                options={{
                                  plugins: {
                                    legend: {
                                      display: true,
                                    },
                                    title: {
                                      font: {
                                        size: 20,
                                      },
                                      display: true,
                                      text: itm.UserName,
                                    },
                                  },
                                  responsive: true,
                                  scales: {
                                    x: {
                                      // innerHeight: "50%",
                                      stacked: true,
                                    },
                                    y: {
                                      // innerHeight: "50%",
                                      stacked: true,
                                    },
                                  },
                                }}
                                data={{
                                  labels,
                                  datasets: handleBarsData(itm),
                                }}
                              />
                            </div>
                          </Col>
                        )}
                      </>
                    ))}
                </Row>
              </Container>
            </>
          ) : (
            <>
              <div className="opportunity">
                <div className="noRecordFound">
                  <img src={noRecordFound} alt="" />
                </div>
              </div>
            </>
          )
        ) : (
          <PageLoader />
        )}
      </Modal.Body>
      <Modal.Footer className="pt-2">
        <Button variant="secondary" onClick={handleClosePopup}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          variant="primary ms-0"
          // disabled={!loadProcessPDF ? false : true}
          disabled={!loadProcessPDF ? false : true}
          onClick={() => exportActivityGoalReportPDF()}
        >
          <span className="me-2">
            <img src={PdfIcon} alt="" />
          </span>
          Export PDF
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenerateActivityGoalsReports;
