import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import PageLoader from "../../Component/Loader/PageLoader";
import { useLocation } from "react-router-dom";
import { SupportDetailBySupportID, getSupportDataById } from "../../Services/LeftMenuServices";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import {
  CommonService,
  ToasterError,
  fnCheckValidationOfObject,
  getDateInFormat,
  getTimeInFormat,
} from "../../Services/CommonServices";
import { SupportDetailSave } from "../../Services/Setup&Config/AdditionalSettingService";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import RedLabel from "../../Component/Labels/RedLabel/Index";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";
import AddSupportCommentModal from "../../Model/AddSupportCommentModal";

function SupportDetailView() {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [supportData, setSupportData]: any = useState({});
  const [supportDetailsData, setSupportDetailsData]: any = useState([]);
  const [handleSinner, sethandleSinner] = useState(false);
  const [showSupportCommentModal, setShowSupportCommentModal] = useState(false);

  const [loading, setLoading] = useState(false);
  let location: any = useLocation();

  async function getSupportTicketdata(SupportID) {
    let input = {
      keyID: SupportID,
    };
    setLoading(true);
    const res: any = await getSupportDataById(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          
          setSupportData(res.data.data);
          // setViewID(res.data.data.supportID);
          //           setViewClientID(res.data.data.clientID);
          //           setViewClientUserName(res.data.data.userID);
          setLoading(false);
        } else {
          // setSupportlist([]);
          setLoading(false);
        }
      }
    }
  }

  async function getSupportDetailBySupportID(SupportID) {
    let body: any = {
      keyID: SupportID,
    };
    sethandleSinner(true);
    const res: any = await SupportDetailBySupportID(body, Token);
    if (res.data && res.data !== undefined) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setSupportDetailsData(res.data.data.dataList1);
          sethandleSinner(false);
        }
      } else {
        ToasterError(res.message);
      }
    } else {
      ToasterError(res.message);
    }
    sethandleSinner(false);
  }

  useEffect(() => {
    if (location.state && location.state.SupportID) {
      getSupportTicketdata(location.state.SupportID);
      getSupportDetailBySupportID(location.state.SupportID);
    }
  }, []);

  return (
    <>
      <Container fluid={true} className="p-0 position-relative">
        {/* <div className="btn-icon-grp justify-content-end">Add buttons section</div> */}
        {!loading ? (
          <>
            <Row>
              <Col lg={12} className="mb-3">
                <div className="information-division">
                  <h3 className="inner-header m-0">Details :</h3>
                  <div className="tab-inner-box position-relative">
                    <Row>
                      <Col xs={12} md={6} lg={2} className="mb-3">
                        <span className="box-label">Category</span>
                        <p className="box-detials">
                          {supportData.category !== null &&
                          supportData.category !== undefined &&
                          supportData.category !== ""
                            ? supportData.category
                            : "-"}
                        </p>
                      </Col>
                      <Col xs={12} md={6} lg={2} className="mb-3">
                        <span className="box-label">Assign To</span>
                        <p className="box-detials">
                          {supportData.assignToUserName !== null &&
                          supportData.assignToUserName !== undefined &&
                          supportData.assignToUserName !== ""
                            ? supportData.assignToUserName
                            : "-"}
                        </p>
                      </Col>

                      <Col xs={12} md={6} lg={6} className="mb-3">
                        <span className="box-label">Title</span>
                        <p className="box-detials">
                          {supportData.title !== null &&
                          supportData.title !== undefined &&
                          supportData.title !== ""
                            ? supportData.title
                            : "-"}
                        </p>
                      </Col>
                      <Col xs={12} md={6} lg={2} className="mb-3">
                        {/* <span className="box-label">Status</span> */}
                        <p className="box-detials">
                          {supportData !== undefined && supportData.status === "Open" ? (
                            <RedLabel
                              dangerLabel={supportData !== undefined && supportData.status}
                            />
                          ) : (
                            <GreenLabel
                              successLabel={supportData !== undefined && supportData.status}
                            />
                          )}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {supportData.status !== "Closed" && (
                <div className="d-flex justify-content-end mt-2 mb-3">
                  <Button onClick={() => setShowSupportCommentModal(true)}>Add Comments</Button>
                </div>
              )}

              <Col xs={12} xl={12} className="mb-3">
                <div className="information-division">
                  <h3 className="inner-header m-0">Comments :</h3>
                  {/* <div className="tab-inner-box position-relative"> */}
                  <Col xs={12} xl={12} className="mb-3">
                    <Card>
                      <Card.Body>
                        <Card.Text>
                          <div className="response-div content_support_details">
                            <div className="font-weight-500">
                              {supportData.userName}
                              <span style={{ float: "right" }}>
                                {getDateInFormat(supportData.createdOn)}{" "}
                                {getTimeInFormat(supportData.createdOn)}
                              </span>
                            </div>
                            <div
                              className="mt-1"
                              dangerouslySetInnerHTML={{ __html: supportData.description }}
                            ></div>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>

                    {supportDetailsData.length > 0 &&
                      supportDetailsData.map((item, index) => (
                        <>
                          <Card>
                            <Card.Body>
                              <Card.Text>
                                <div
                                  className="response-div content_support_details mt-1"
                                  key={index}
                                >
                                  <div className="font-weight-500">
                                    {item.ReplyBy}
                                    <span style={{ float: "right" }}>
                                      {getDateInFormat(item.CreatedOn)}
                                      {getTimeInFormat(item.CreatedOn)}
                                    </span>
                                  </div>
                                  <div
                                    className="mt-1"
                                    dangerouslySetInnerHTML={{ __html: item.Comment }}
                                  ></div>{" "}
                                </div>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </>
                      ))}

                    {handleSinner ? (
                      <Card>
                        <Card.Body>
                          <Card.Text>
                            <Skeleton count={1} height={15} />
                            <Skeleton count={1} height={15} />
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    ) : (
                      <></>
                    )}
                  </Col>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <PageLoader />
        )}
      </Container>

      {showSupportCommentModal && (
        <AddSupportCommentModal
          supportData={supportData}
          getSupportDetailBySupportID={getSupportDetailBySupportID}
          setModalShow={setShowSupportCommentModal}
          modalShow={showSupportCommentModal}
        />
      )}
    </>
  );
}

export default SupportDetailView;
