import img1 from "../Asset/Images/AvtarImages/1.png";
import img2 from "../Asset/Images/AvtarImages/2.png";
import img3 from "../Asset/Images/AvtarImages/3.png";
import img4 from "../Asset/Images/AvtarImages/4.png";
import img5 from "../Asset/Images/AvtarImages/5.png";
import img6 from "../Asset/Images/AvtarImages/6.png";
import img7 from "../Asset/Images/AvtarImages/7.png";
import img8 from "../Asset/Images/AvtarImages/8.png";
import img9 from "../Asset/Images/AvtarImages/9.png";
import img10 from "../Asset/Images/AvtarImages/10.png";
import img11 from "../Asset/Images/AvtarImages/11.png";
import img12 from "../Asset/Images/AvtarImages/12.png";
import img13 from "../Asset/Images/AvtarImages/13.png";
import img14 from "../Asset/Images/AvtarImages/14.png";
import img15 from "../Asset/Images/AvtarImages/15.png";
import img16 from "../Asset/Images/AvtarImages/16.png";
import img17 from "../Asset/Images/AvtarImages/17.png";
import img18 from "../Asset/Images/AvtarImages/18.png";
import img19 from "../Asset/Images/AvtarImages/19.png";
import img20 from "../Asset/Images/AvtarImages/20.png";
import img21 from "../Asset/Images/AvtarImages/21.png";
import img22 from "../Asset/Images/AvtarImages/22.png";
import img23 from "../Asset/Images/AvtarImages/23.png";
import img24 from "../Asset/Images/AvtarImages/24.png";
import img25 from "../Asset/Images/AvtarImages/25.png";
import img26 from "../Asset/Images/AvtarImages/26.png";
import img27 from "../Asset/Images/AvtarImages/27.png";
import img28 from "../Asset/Images/AvtarImages/28.png";
import img29 from "../Asset/Images/AvtarImages/29.png";
import img30 from "../Asset/Images/AvtarImages/30.png";
import img32 from "../Asset/Images/AvtarImages/32.png";
import img33 from "../Asset/Images/AvtarImages/33.png";
import img34 from "../Asset/Images/AvtarImages/34.png";
import img35 from "../Asset/Images/AvtarImages/35.png";
import img36 from "../Asset/Images/AvtarImages/36.png";
import img37 from "../Asset/Images/AvtarImages/37.png";
import img38 from "../Asset/Images/AvtarImages/38.png";
import img39 from "../Asset/Images/AvtarImages/39.png";
import img40 from "../Asset/Images/AvtarImages/40.png";
import img41 from "../Asset/Images/AvtarImages/41.png";
import img42 from "../Asset/Images/AvtarImages/42.png";
import img43 from "../Asset/Images/AvtarImages/43.png";
import img44 from "../Asset/Images/AvtarImages/44.png";
import img45 from "../Asset/Images/AvtarImages/45.png";
import img46 from "../Asset/Images/AvtarImages/46.png";
import img47 from "../Asset/Images/AvtarImages/47.png";
import img48 from "../Asset/Images/AvtarImages/48.png";
import img50 from "../Asset/Images/AvtarImages/50.png";

export const ENUMS: any = {
  actions: {
    UPDATE_USER: "UPDATE USER DETAILS",
    SET_IS_LOGGED: "SET_IS_LOGGED",
    SET_USER_DETAILS: "SET_USER_DETAILS",
    ACCESS_TOKEN: "ACCESS_TOKEN",
    UPDATE_HOTEL_DETAILS: "UPDATE_HOTEL_DETAILS",
    DASHBOARD_DETAILS: "DASHBOARD_DETAILS",
    YEAR_DETAILS: "YEAR_DETAILS",
    SELECTED_LOGO: "SELECTED_LOGO",
    SELECTED_PROPERTY: "SELECTED_PROPERTY",
    SELECTED_PROPERTY_NAME: "SELECTED_PROPERTY_NAME",
    SELECTED_PORTFOLIO: "SELECTED_PORTFOLIO",
    SELECTED_PORTFOLIO_ID: "SELECTED_PORTFOLIO_ID",
    SELECTED_ACCOUNT: "SELECTED_ACCOUNT",
    SELECTED_CONTACT: "SELECTED_CONTACT",
    ISUPDATE_LOGO: "ISUPDATE_LOGO",
    ISLOGOCHANGEDATA: "ISLOGOCHANGEDATA",
    UPDATE_AUTH_USER_DTLS: "UPDATE_AUTH_USER_DTLS",
    GLOBAL_HOTELS: "GLOBAL_HOTELS",
    UPDATE_LEAD_FETCH_COUNT: "UPDATE_LEAD_FETCH_COUNT",
    UPDATE_ACCOUNT_FETCH_COUNT: "UPDATE_ACCOUNT_FETCH_COUNT",
    UPDATE_CONTACT_FETCH_COUNT: "UPDATE_CONTACT_FETCH_COUNT",
    UPDATE_ACTIVITY_FETCH_COUNT: "UPDATE_ACTIVITY_FETCH_COUNT",
    ALL_CLIENTS: "ALL_CLIENTS",
  },
  FromProperty: {
    LNR: "LNR",
    Group: "Group",
    MAC: "Meeting & Catering",
    ComboGroupMeeting: "Group & Meeting Combo",
  },
  side_menu: {
    dashboard: {
      title: "Dashboard",
      Icon: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 18.75C16.3807 18.75 17.5 17.6307 17.5 16.25C17.5 14.8693 16.3807 13.75 15 13.75C13.6193 13.75 12.5 14.8693 12.5 16.25C12.5 17.6307 13.6193 18.75 15 18.75Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.8125 14.4375L19.375 11.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 25C6.17269 23.5476 4.84239 21.5628 4.19347 19.3206C3.54456 17.0784 3.60915 14.6899 4.37831 12.486C5.14746 10.2822 6.58309 8.37214 8.48623 7.02063C10.3894 5.66912 12.6658 4.94305 15 4.94305C17.3342 4.94305 19.6106 5.66912 21.5138 7.02063C23.4169 8.37214 24.8525 10.2822 25.6217 12.486C26.3908 14.6899 26.4554 17.0784 25.8065 19.3206C25.1576 21.5628 23.8273 23.5476 22 25H8Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      child: [],
      link: "/home",
      hasChild: false,
      RightName: "Dashboard",
      is_view: true,
      is_create: true,
      is_update: true,
      is_delete: true,
      is_export: true,
    },
    suspect: {
      title: "Lead",
      Icon: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.75 13.75V23.75C8.75 24.0815 8.6183 24.3995 8.38388 24.6339C8.14946 24.8683 7.83152 25 7.5 25H5C4.66848 25 4.35054 24.8683 4.11612 24.6339C3.8817 24.3995 3.75 24.0815 3.75 23.75V15C3.75 14.6685 3.8817 14.3505 4.11612 14.1161C4.35054 13.8817 4.66848 13.75 5 13.75H8.75ZM8.75 13.75C10.0761 13.75 11.3479 13.2232 12.2855 12.2855C13.2232 11.3479 13.75 10.0761 13.75 8.75V7.5C13.75 6.83696 14.0134 6.20107 14.4822 5.73223C14.9511 5.26339 15.587 5 16.25 5C16.913 5 17.5489 5.26339 18.0178 5.73223C18.4866 6.20107 18.75 6.83696 18.75 7.5V13.75H22.5C23.163 13.75 23.7989 14.0134 24.2678 14.4822C24.7366 14.9511 25 15.587 25 16.25L23.75 22.5C23.5702 23.2668 23.2292 23.9253 22.7783 24.3762C22.3274 24.8271 21.791 25.046 21.25 25H12.5C11.5054 25 10.5516 24.6049 9.84835 23.9017C9.14509 23.1984 8.75 22.2446 8.75 21.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      child: [],
      link: "/lead",
      hasChild: false,
      RightName: "Lead",
      is_view: false,
      is_create: false,
      is_update: false,
      is_delete: false,
      is_export: false,
    },
    accounts: {
      title: "Account",
      Icon: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.5 6.25H7.5C5.42893 6.25 3.75 7.92893 3.75 10V20C3.75 22.0711 5.42893 23.75 7.5 23.75H22.5C24.5711 23.75 26.25 22.0711 26.25 20V10C26.25 7.92893 24.5711 6.25 22.5 6.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.75 12.5H26.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.75 18.75H8.76417" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.75 18.75H16.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      child: [],
      link: "/account",
      hasChild: false,
      RightName: "Account",
      is_view: false,
      is_create: false,
      is_update: false,
      is_delete: false,
      is_export: false,
    },
    contacts: {
      title: "Contacts",
      Icon: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.25 5H11.25L13.75 11.25L10.625 13.125C11.9637 15.8394 14.1606 18.0363 16.875 19.375L18.75 16.25L25 18.75V23.75C25 24.413 24.7366 25.0489 24.2678 25.5178C23.7989 25.9866 23.163 26.25 22.5 26.25C17.6241 25.9537 13.0252 23.8831 9.57104 20.429C6.11688 16.9748 4.04631 12.3759 3.75 7.5C3.75 6.83696 4.01339 6.20107 4.48223 5.73223C4.95107 5.26339 5.58696 5 6.25 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      child: [],
      link: "/contact",
      hasChild: false,
      RightName: "Contacts",
      is_view: false,
      is_create: false,
      is_update: false,
      is_delete: false,
      is_export: false,
    },
    activities: {
      title: "Activities",
      Icon: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 26.25C21.2132 26.25 26.25 21.2132 26.25 15C26.25 8.7868 21.2132 3.75 15 3.75C8.7868 3.75 3.75 8.7868 3.75 15C3.75 21.2132 8.7868 26.25 15 26.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 8.75V15L18.75 18.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      child: [],
      link: "/activityList",
      hasChild: false,
      RightName: "Activities",
      is_view: false,
      is_create: false,
      is_update: false,
      is_delete: false,
      is_export: false,
    },
    opportunities: {
      title: "Opportunity",
      Icon: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.21054 16.0974V19.2625V24.8478H25.4382V2.50632H8.21054V8.0917V11.2567" stroke="white" stroke-width="1.5"/>
      <path d="M16.4912 5.797L25.9091 2.04546V25.3259L16.4912 27.9546V22.2452V19.5487V18.2005V16.8523V5.797Z" fill="white"/>
      <path d="M4.09091 13.6771H10.6449M10.6449 13.6771L9.14684 12.1876M10.6449 13.6771L9.14684 15.1665" stroke="white"/>
      </svg>`,
      child: [
        {
          title: "RFP",
          link: "/opportunity/rfp",
          RightName: "RFP",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          hasChild: false,
          // Icon: `<i className="fa-solid fa-circle small-font"></i>`
        },
        {
          title: "LNR",
          link: "/opportunity/lnr",
          RightName: "LNR",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          hasChild: false,
          // Icon: `<i className="fa-solid fa-circle small-font"></i>`
        },
        {
          title: "Group",
          link: "/opportunity/group",
          RightName: "Group",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          hasChild: false,
          // Icon: `<i className="fa-solid fa-circle small-font"></i>`
        },
        {
          title: "Meeting & Catering",
          link: "/opportunity/meetingAndCatering",
          RightName: "MeetingAndCatering",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          hasChild: false,
          // Icon: `<i className="fa-solid fa-circle small-font"></i>`
        },
      ],
      link: "#",
      hasChild: true,
    },
    import: {
      title: "Import",
      Icon: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.25 5H11.25L13.75 11.25L10.625 13.125C11.9637 15.8394 14.1606 18.0363 16.875 19.375L18.75 16.25L25 18.75V23.75C25 24.413 24.7366 25.0489 24.2678 25.5178C23.7989 25.9866 23.163 26.25 22.5 26.25C17.6241 25.9537 13.0252 23.8831 9.57104 20.429C6.11688 16.9748 4.04631 12.3759 3.75 7.5C3.75 6.83696 4.01339 6.20107 4.48223 5.73223C4.95107 5.26339 5.58696 5 6.25 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      child: [],
      link: "/import",
      hasChild: false,
      RightName: "Import",
      is_view: false,
      is_create: false,
      is_update: false,
      is_delete: false,
      is_export: false,
    },
    defaultAuthorized: {
      title: "",
      Icon: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 26.25C21.2132 26.25 26.25 21.2132 26.25 15C26.25 8.7868 21.2132 3.75 15 3.75C8.7868 3.75 3.75 8.7868 3.75 15C3.75 21.2132 8.7868 26.25 15 26.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 8.75V15L18.75 18.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      child: [],
      link: "",
      hasChild: true,
      // RightName: "Activities",
      is_view: false,
      is_create: false,
      is_update: false,
      is_delete: false,
      is_export: false,
    },
    // miscallaneous: {
    //   title: "Miscellaneous",
    //   Icon: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M12.9062 5.39625C13.4387 3.20125 16.5613 3.20125 17.0938 5.39625C17.1736 5.726 17.3303 6.03222 17.5509 6.29C17.7715 6.54778 18.0499 6.74982 18.3633 6.87968C18.6768 7.00955 19.0165 7.06356 19.3547 7.03734C19.693 7.01111 20.0203 6.90538 20.31 6.72875C22.2387 5.55375 24.4475 7.76125 23.2725 9.69125C23.0961 9.98082 22.9906 10.3079 22.9644 10.646C22.9382 10.984 22.9922 11.3235 23.1219 11.6367C23.2516 11.95 23.4534 12.2282 23.7109 12.4488C23.9684 12.6694 24.2743 12.8261 24.6038 12.9062C26.7988 13.4387 26.7988 16.5613 24.6038 17.0938C24.274 17.1736 23.9678 17.3303 23.71 17.5509C23.4522 17.7715 23.2502 18.0499 23.1203 18.3633C22.9905 18.6768 22.9364 19.0165 22.9627 19.3547C22.9889 19.693 23.0946 20.0203 23.2713 20.31C24.4463 22.2387 22.2387 24.4475 20.3087 23.2725C20.0192 23.0961 19.6921 22.9906 19.354 22.9644C19.016 22.9382 18.6765 22.9922 18.3633 23.1219C18.05 23.2516 17.7718 23.4534 17.5512 23.7109C17.3306 23.9684 17.1739 24.2743 17.0938 24.6038C16.5613 26.7988 13.4387 26.7988 12.9062 24.6038C12.8264 24.274 12.6697 23.9678 12.4491 23.71C12.2285 23.4522 11.9501 23.2502 11.6367 23.1203C11.3232 22.9905 10.9835 22.9364 10.6453 22.9627C10.307 22.9889 9.97969 23.0946 9.69 23.2713C7.76125 24.4463 5.5525 22.2387 6.7275 20.3087C6.90388 20.0192 7.00944 19.6921 7.0356 19.354C7.06177 19.016 7.0078 18.6765 6.87809 18.3633C6.74838 18.05 6.54658 17.7718 6.28909 17.5512C6.03161 17.3306 5.7257 17.1739 5.39625 17.0938C3.20125 16.5613 3.20125 13.4387 5.39625 12.9062C5.726 12.8264 6.03222 12.6697 6.29 12.4491C6.54778 12.2285 6.74982 11.9501 6.87968 11.6367C7.00955 11.3232 7.06356 10.9835 7.03734 10.6453C7.01111 10.307 6.90538 9.97969 6.72875 9.69C5.55375 7.76125 7.76125 5.5525 9.69125 6.7275C10.9412 7.4875 12.5612 6.815 12.9062 5.39625Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    //   <path d="M15 18.75C17.0711 18.75 18.75 17.0711 18.75 15C18.75 12.9289 17.0711 11.25 15 11.25C12.9289 11.25 11.25 12.9289 11.25 15C11.25 17.0711 12.9289 18.75 15 18.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    //   </svg>`,
    //   child: [
    //     {
    //       title: "Document",
    //       link: "/miscellaneous/uploadDocument",
    //       RightName: "Document",
    //       is_view: false,
    //       is_create: false,
    //       is_update: false,
    //       is_delete: false,
    //       is_export: false,
    //       hasChild: false,
    //       // Icon: `<i className="fa-solid fa-circle small-font"></i>`
    //     },
    //     {
    //       title: "Calendar",
    //       link: "/miscellaneous/calendar",
    //       RightName: "Calendar",
    //       is_view: false,
    //       is_create: false,
    //       is_update: false,
    //       is_delete: false,
    //       is_export: false,
    //       hasChild: false,
    //       // Icon: `<i className="fa-solid fa-circle small-font"></i>`
    //     }
    //   ],
    //   link: "#",
    //   hasChild: true,
    // },
    reports: {
      title: "Reports",
      Icon: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.5909 4.77273C25.6955 4.77273 26.5909 5.66816 26.5909 6.77273V23.5227C26.5909 23.9748 26.4552 24.4084 26.2137 24.728C25.9722 25.0477 25.6446 25.2273 25.303 25.2273H4.69697C4.3554 25.2273 4.02782 25.0477 3.7863 24.728C3.54477 24.4084 3.40909 23.9748 3.40909 23.5227V6.47727C3.40909 6.0252 3.54477 5.59164 3.7863 5.27198C4.02782 4.95231 4.3554 4.77273 4.69697 4.77273H24.5909Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.5 17.1591V22.1591" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.5 13.4091V22.1591" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.5 12.1591V22.1591" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.5 10.9091V22.1591" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      child: [],
      link: "/Reports",
      hasChild: false,
      RightName: "Reports",
      is_view: true,
      is_create: true,
      is_update: true,
      is_delete: true,
      is_export: true,
    },
    setup: {
      title: "Setup and Configuration",
      Icon: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.25 5C25.3546 5 26.25 5.89543 26.25 7V18.75C26.25 19.0815 26.1183 19.3995 25.8839 19.6339C25.6495 19.8683 25.3315 20 25 20H5C4.66848 20 4.35054 19.8683 4.11612 19.6339C3.8817 19.3995 3.75 19.0815 3.75 18.75V6.25C3.75 5.91848 3.8817 5.60054 4.11612 5.36612C4.35054 5.1317 4.66848 5 5 5H24.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.75 25H21.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.25 20V25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.75 20V25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      child: [
        {
          title: "Company Profile",
          link: "/SetupAndConfigurations/companyProfile",
          RightName: "Company",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          hasChild: false,
          // Icon: `<i className="fa-solid fa-circle small-font"></i>`
        },
        {
          title: "General Settings",
          link: "/SetupAndConfigurations/generalsetting",
          RightName: "GeneralSettings",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          hasChild: false,
          // Icon: `<i className="fa-solid fa-circle small-font"></i>`
        },
        // {
        //   title: "Mail Settings",
        //   link: "/SetupAndConfigurations/mailsetting",
        //   RightName: "MailSettings",
        //   is_view: false,
        //   is_create: false,
        //   is_update: false,
        //   is_delete: false,
        //   is_export: false,
        //   hasChild: false,
        //   // Icon: `<i className="fa-solid fa-circle small-font"></i>`
        // },
        {
          title: "Portfolios",
          link: "/SetupAndConfigurations/portfolios",
          RightName: "Portfoilios",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          hasChild: false,
          // Icon: `<i className="fa-solid fa-circle small-font"></i>`
        },
        {
          title: "Hotels",
          link: "/SetupAndConfigurations/hotels",
          RightName: "Hotels",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          hasChild: false,
          // Icon: `<i className="fa-solid fa-circle small-font"></i>`
        },
        {
          title: "User Management",
          link: "#",
          hasChild: true,
          child: [
            {
              title: "User",
              link: "/SetupAndConfigurations/user",
              RightName: "User",
              is_view: false,
              is_create: false,
              is_update: false,
              is_delete: false,
              is_export: false,
              hasChild: false,
              // Icon: `<i className="fa-solid fa-circle small-font"></i>`
            },
            {
              title: "User Role",
              link: "/SetupAndConfigurations/userRole",
              RightName: "UserRole",
              is_view: false,
              is_create: false,
              is_update: false,
              is_delete: false,
              is_export: false,
              hasChild: false,
              // Icon: `<i className="fa-solid fa-circle small-font"></i>`
            },
          ],
        },
        {
          title: "Account Rules",
          link: "/SetupAndConfigurations/accountRules",
          RightName: "AccountRules",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          hasChild: false,
          // Icon: `<i className="fa-solid fa-circle small-font"></i>`
        },
        {
          title: "Market Segment Type",
          link: "/SetupAndConfigurations/marketSegmentType",
          RightName: "MarketSegmentType",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          hasChild: false,
          // Icon: `<i className="fa-solid fa-circle small-font"></i>`
        },
        {
          title: "Additional Settings",
          link: "/SetupAndConfigurations/additionalSettings",
          RightName: "AdditionalSettings",
          is_view: false,
          is_create: false,
          is_update: false,
          is_delete: false,
          is_export: false,
          hasChild: false,
          // Icon: `<i className="fa-solid fa-circle small-font"></i>`
        },
      ],
      link: "#",
      hasChild: true,
    },
  },
  ClientRolesEnums: [
    "Admin Property Level",
    "Property  Level Admin",
    "Property Admin",
    "Property Level Admin",
    "Property Level Administrator",
    "Property Level Admins",
    "Administrator",
  ],

  HotelTokens: [
    { tokenName: "Hotel Name", tokenValue: "[$$propertyname$$]" },
    // { tokenName: "Hotel Tax rate", tokenValue: "[$$propertytaxrate$$]" },
    { tokenName: "Hotel Address", tokenValue: "[$$propertyaddress$$]" },
    { tokenName: "Hotel Country", tokenValue: "[$$propertycountry$$]" },
    { tokenName: "Hotel State", tokenValue: "[$$propertystate$$]" },
    // { tokenName: "Hotel Region", tokenValue: "[$$propertyregion$$]" },
    // { tokenName: "Hotel Area", tokenValue: "[$$propertyarea$$]" },
    { tokenName: "Hotel Zipcode", tokenValue: "[$$propertyzipcode$$]" },
    { tokenName: "Hotel City", tokenValue: "[$$propertycity$$]" },
    { tokenName: "Hotel Hotel Phone", tokenValue: "[$$propertyhotelphone$$]" },
    // { tokenName: "Hotel Primary Contact No.", tokenValue: "[$$propertyprimarycontactno$$]" },
    // { tokenName: "Hotel Secondary Contact No.", tokenValue: "[$$propertysecondarycontactno$$]" },
    // { tokenName: "Hotel Primary Email ", tokenValue: "[$$propertyprimaryemailid$$]" },
    // { tokenName: "Hotel Secondary Email", tokenValue: "[$$propertysecondaryemailid$$]" },
    { tokenName: "Hotel Property Logo", tokenValue: "[$$propertypropertylogo$$]" },
  ],

  MACTokens: [
    { tokenName: "Opportunity Name", tokenValue: "[$$opportunityname$$]" },
    { tokenName: "Account Name", tokenValue: "[$$accountname$$]" },
    // { tokenName: "Contact", tokenValue: "[$$contact$$]" },
    // { tokenName: "Contact Title", tokenValue: "[$$contacttitle$$]" },
    // { tokenName: "Contact Name", tokenValue: "[$$contactname$$]" },
    // { tokenName: "Address", tokenValue: "[$$address$$]" },
    // { tokenName: "Contact City", tokenValue: "[$$contactcity$$]" },
    // { tokenName: "Contact State", tokenValue: "[$$contactstate$$]" },
    // { tokenName: "Contact Country", tokenValue: "[$$contactcountry$$]" },
    // { tokenName: "Contact Zipcode", tokenValue: "[$$contactzipcode$$]" },
    // { tokenName: "Email", tokenValue: "[$$email$$]" },
    // { tokenName: "Onsite Contact", tokenValue: "[$$onsitecontact$$]" },

    // { tokenName: "Start Date", tokenValue: "[$$startdate$$]" },
    // { tokenName: "End Date", tokenValue: "[$$enddate$$]" },
    // { tokenName: "Meeting Room Grid", tokenValue: "[$$meetingroomgrid$$]" },
    // { tokenName: "Food Grid", tokenValue: "[$$foodgrid$$]" },
    // { tokenName: "Beverage Grid", tokenValue: "[$$beveragegrid$$]" },
    // { tokenName: "AV Misc Grid", tokenValue: "[$$avmiscgrid$$]" },

    { tokenName: "Special Instruction", tokenValue: "[$$specialinstruction$$]" },
    { tokenName: "Payment Method", tokenValue: "[$$paymentmethod$$]" },
    { tokenName: "Current Date", tokenValue: "[$$currentdate$$]" },
    // { tokenName: "Food and Beverage Grid", tokenValue: "[$$foodandbeveragegrid$$]" },
    // { tokenName: "Total Amount", tokenValue: "[$$totalamount$$]" },
    // { tokenName: "Tax Amount", tokenValue: "[$$taxamount$$]" },
    // { tokenName: "Tax Percentage", tokenValue: "[$$taxpercentage$$]" },
    // { tokenName: "Gross Total", tokenValue: "[$$grosstotal$$]" },

    // { tokenName: "Meeting Tax Detail Grid", tokenValue: "[$$meetingtaxdetailgrid$$]" },
    // { tokenName: "AV Tax Detail Grid", tokenValue: "[$$avtaxdetailgrid$$]" },
    // { tokenName: "Food Tax Detail Grid", tokenValue: "[$$foodtaxdetailgrid$$]" },
    // { tokenName: "Beverage Tax Detail Grid", tokenValue: "[$$beveragetaxdetailgrid$$]" },

    // { tokenName: "Meeting and AV Tax", tokenValue: "[$$meetingandavtax$$]" },
    // { tokenName: "Food and Beverage tax", tokenValue: "[$$foodandbeveragetax$$]" },

    // {
    //   tokenName: "Meeting Room Total",
    //   tokenValue: "[$$meetingroomtotal$$]",
    // },
    // {
    //   tokenName: "AV Total",
    //   tokenValue: "[$$avtotal$$]",
    // },
    // {
    //   tokenName: "Food Total",
    //   tokenValue: "[$$foodtotal$$]",
    // },
    // {
    //   tokenName: "Beverage Total",
    //   tokenValue: "[$$beveragetotal$$]",
    // },
    // {
    //   tokenName: "Meeting and AV Total",
    //   tokenValue: "[$$meetingandavtotal$$]",
    // },
    // {
    //   tokenName: "Food and Beverage Total",
    //   tokenValue: "[$$foodandbeveragetotal$$]",
    // },
    // {
    //   tokenName: "Meeting Room Tax",
    //   tokenValue: "[$$meetingroomtax$$]",
    // },
    // {
    //   tokenName: "AV Tax",
    //   tokenValue: "[$$avtax$$]",
    // },
    // {
    //   tokenName: "Food Tax",
    //   tokenValue: "[$$foodtax$$]",
    // },
    // {
    //   tokenName: "Beverage Tax",
    //   tokenValue: "[$$beveragetax$$]",
    // },
    // {
    //   tokenName: "Meeting and AV Tax",
    //   tokenValue: "[$$meetingandavtax$$]",
    // },
    // {
    //   tokenName: " Food and Beverage Tax",
    //   tokenValue: "[$$foodandbeveragetax$$]",
    // },
    // {
    //   tokenName: "Meeting Room Gross Total",
    //   tokenValue: "[$$meetingroomgrosstotal$$]",
    // },
    // {
    //   tokenName: "AV Gross Total",
    //   tokenValue: "[$$avgrosstotal$$]",
    // },
    // {
    //   tokenName: "Food Gross Total",
    //   tokenValue: "[$$foodgrosstotal$$]",
    // },
    // {
    //   tokenName: "Beverage Gross Total",
    //   tokenValue: "[$$beveragegrosstotal$$]",
    // },
    // {
    //   tokenName: "Meeting and AV Gross Total",
    //   tokenValue: "[$$meetingandavgrosstotal$$]",
    // },
    // {
    //   tokenName: "Food and Beverage Gross Total",
    //   tokenValue: "[$$foodandbeveragegrosstotal$$]",
    // },
    // {
    //   tokenName: "Meeting Room Tax Percentage",
    //   tokenValue: "[$$meetingroomtaxpercentage$$]",
    // },
    // {
    //   tokenName: "AV Tax Percentage",
    //   tokenValue: "[$$avtaxpercentage$$]",
    // },
    // {
    //   tokenName: "Food Tax Percentage",
    //   tokenValue: "[$$foodtaxpercentage$$]",
    // },
    // {
    //   tokenName: "Beverage Tax Percentage",
    //   tokenValue: "[$$beveragetaxpercentage$$]",
    // },
    // {
    //   tokenName: "Meeting and AV Tax Percentage",
    //   tokenValue: "[$$meetingandavtaxpercentage$$]",
    // },
    // {
    //   tokenName: "Food and Beverage Tax Percentage",
    //   tokenValue: "[$$foodandbeveragetaxpercentage$$]",
    // },
  ],

  GmailLinkWith: ["Lead", "Account", "Contact", "RFP", "LNR", "Group", "Meeting and Catering"],

  AvtarList: [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
    img43,
    img44,
    img45,
    img46,
    img47,
    img48,
    img50,
  ],

  filtersPageList: [
    "dashboardFilter",
    "leadFilter",
    "accountFilter",
    "contactFilter",
    "activityFilter",
    "rfpFilter",
    "lnrFilter",
    "groupFilter",
    "meetingCateringFilter",
    "hotelFilter",
    "companyprofileFilter",
    "generalSettingFilter",
    "portfolioFilter",
    "userFilter",
    "userRoleFilter",
    "accountRuleFilter",
    "marketSegmentFilter",
    "additionalSettingFilter",
    "supportFilter",
    "knowledgeTutorialsFilter",
    "importFilter",
    // ---------------- Report-Filters
    "reportFilter", // In Report navigations
    "dashboardWeeklyReportFilter",
    "weeklyReportFilter",
    "marketingPlanReportFilter",
    "monthlyReportFilter",
    "activityGoalReportFilter",
    "activityReportFilter",
    "traceReportFilter",
    "monthlyRoleupReportFilter",
    "rfpGridReportFilter",
    "lnrGridReportFilter",
    "groupGridReportFilter",
    "meetingCateringGridReportFilter",
    "salesFunnelReportFilter",
    "lostBusinessGridReportFilter",
    "groupRoomCountReportFilter",
    "meetingRoomBookedReportFilter",
    "oppoBySourceReportFilter",
    "meetingCateringByDateReportFilter",
    "groupPaceReportFilter",
    "meetingPaceReportFilter",
    "leadReportFilter",
    "accountReportFilter",
    "actualReportFilter",
  ],

  specialCharKeyCodes: [
    33, // !
    34, // "
    35, // #
    36, // $
    37, // %
    38, // &
    39, // '
    40, // (
    41, // )
    42, // *
    43, // +
    // 44, // ,
    45, // -
    // 46, // .
    47, // /
    58, // :
    59, // ;
    60, // <
    61, // =
    62, // >
    63, // ?
    64, // @
    91, // [
    92, // \
    93, // ]
    94, // ^
    95, // _
    96, // `
    123, // {
    124, // |
    125, // }
    126, // ~
  ],

  alphabetKeycodes: [
    65, // a
    66, // b
    67, // c
    68, // d
    69, // e
    70, // f
    71, // g
    72, // h
    73, // i
    74, // j
    75, // k
    76, // l
    77, // m
    78, // n
    79, // o
    80, // p
    81, // q
    82, // r
    83, // s
    84, // t
    85, // u
    86, // v
    87, // w
    88, // x
    89, // y
    90, // z
  ],

  alphabetKeys: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],

  Alpha_FilterKeys: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],

};
