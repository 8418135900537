import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Button, Col, FloatingLabel, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import GenerateReport from "../../Asset/Images/Other/generate-report.png";
import PageLoader from "../../Component/Loader/PageLoader";
import OpportunityBySourceReportModal from "../../Model/Reports/OpportunityBySourceReportModal";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  fnCheckValidationOfObject,
} from "../../Services/CommonServices";
import { getEmailAssignToDropdownList } from "../../Services/LeftMenuServices";
import { getProjectTermByCategory } from "../../Services/Setup&Config/AdditionalSettingService";
import { GetHotelsListForReports } from "../../Services/Setup&Config/HotelService";
import Unauthorized from "../Authentication/Unauthorized";
import ReportNavigations from "./ReportNavigations";

function OpportunityBySourceReport() {
  let OpportunityBySource_page_rights = CommonService.getPageRight("OpportunityBySource");

  let iniInputData = {
    propertyNames: "",
    propertyIDs: "",
    userIDs: "",
    source: "",
    startDate: "",
    endDate: "",
    errors: {
      propertyIDs: "",
      source: "",
      userIDs: "",
      startDate: "",
      endDate: "",
      ValidationRules: [
        {
          FieldName: "userIDs",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "propertyIDs",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "source",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "startDate",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "endDate",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
      ],
    },
  };

  const dataSelector: any = useSelector((state: any) => state.session);
  const [loading, setLoading] = useState(false);
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  const [AllDropdown, setAllDropdown]: any = useState([]);
  const [inputs, setInputs] = useState(iniInputData);

  const [selectedUsers, setSelectedUsers]: any = useState([]);
  const [selectedSourceList, setSelectedSourceList]: any = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [UserDropdownData, setUserDropdownData]: any = useState([]);
  let [PropertiesListData, setPropertiesListData]: any = useState({
    IsData: true,
    data: [],
  });
  const [selHotels, setSelHotels]: any = useState([]);
  const [OppoBySourceReport, setOppoBySourceReport]: any = useState({});
  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  let optionsSource: any = [
    {
      label: "All Source",
      value: "All Source",
    },
  ];

  AllDropdown !== undefined &&
    AllDropdown !== null &&
    AllDropdown.length > 0 &&
    AllDropdown.map((item: any) => {
      if (item.Category === "Source") {
        optionsSource.push({
          label: item.Term,
          value: item.TermID,
        });
      }
    });

  let HotelsDropdown: any = [{ label: "All Hotels", value: "" }];
  let UsersDropdown: any = [
    {
      label: "All Users",
      value: "All Users",
    },
  ];

  PropertiesListData.data !== undefined &&
    PropertiesListData.data !== null &&
    PropertiesListData.data.length > 0 &&
    PropertiesListData.data.map((item: any) => {
      HotelsDropdown.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });
  UserDropdownData !== undefined &&
    UserDropdownData !== null &&
    UserDropdownData.map((item: any) => {
      UsersDropdown.push({
        label: item.UserName,
        value: item.UserID,
      });
    });

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("oppoBySourceReportFilter"))) {
      HandleRemoveOtherFilters("oppoBySourceReportFilter");
      HandleFilters("oppoBySourceReportFilter", {
        pagePath: "/OpportunityBySourceReport",
        pageName: "oppoBySourceReport",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  useEffect(() => {
    if (SelectedPropertyName) {
      if (!SelectedPropertyID.includes(",")) {
        setSelHotels([
          {
            value: SelectedPropertyID,
            label: SelectedPropertyName,
          },
        ]);
        setInputs({
          ...inputs,
          ["propertyIDs"]: SelectedPropertyID,
          ["propertyNames"]: SelectedPropertyName,
        });
        userByProperty(SelectedPropertyID);
      }
    }

    getAllDropdown();
    getHotelsList();
  }, [SelectedPropertyName]);

  async function userByProperty(data) {
    let input = {
      clientID: clientID,
      propertyIDs: data.toString(),
    };
    sethotelChangeLoading(true);
    const res: any = await getEmailAssignToDropdownList(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          if (
            res.data.data.dataList1 !== undefined &&
            res.data.data.dataList1 !== null &&
            res.data.data.dataList1 !== ""
          ) {
            setUserDropdownData(res.data.data.dataList1);
          }
        }
      }
    }
    sethotelChangeLoading(false);
  }

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      userID: userId,
    };
    setPropertiesListData({ IsData: true, data: [] });
    let res: any = await GetHotelsListForReports(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setPropertiesListData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPropertiesListData({ IsData: false, data: [] });
        }
      }
    }
  }

  async function SelectedPropertyData(data: any) {
    let temp: any = [];
    let tempNames: any = [];
    if (data.length) {
      data.map((item: any) => {
        temp.push(item.value);
        tempNames.push(item.label);
      });
      // setInputs({
      //   ...inputs,
      //   ["propertyIDs"]: temp.toString(),
      //   ["propertyNames"]: tempNames,
      // });
      let input = {
        clientID: clientID,
        propertyIDs: temp.toString(),
      };
      sethotelChangeLoading(true);
      const res: any = await getEmailAssignToDropdownList(input, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            if (
              res.data.data.dataList1 !== undefined &&
              res.data.data.dataList1 !== null &&
              res.data.data.dataList1 !== ""
            ) {
              setUserDropdownData(res.data.data.dataList1);
              // Logic for selected user by property.
              if (selectedUsers.length) {
                let resultedUsers: any = [];
                selectedUsers.forEach((element: any) => {
                  if (res.data.data.dataList1.length) {
                    res.data.data.dataList1.forEach((data: any) => {
                      if (data.UserID === element.value) {
                        resultedUsers.push({ label: element.label, value: element.value });
                      }
                    });
                  }
                });
                setSelectedUsers(resultedUsers);
                let fiteredUsers: any = [];
                resultedUsers.forEach((obj) => {
                  fiteredUsers.push(obj.value);
                });

                setInputs({
                  ...inputs,
                  ["propertyIDs"]: temp.toString(),
                  ["propertyNames"]: tempNames,
                  ["userIDs"]: fiteredUsers.toString(),
                });
              } else {
                setInputs({
                  ...inputs,
                  ["propertyIDs"]: temp.toString(),
                });
              }
            }
          }
        }
      }
    } else {
      setInputs({
        ...inputs,
        ["propertyIDs"]: "",
        ["propertyNames"]: "",
        ["userIDs"]: "",
      });
      setSelectedUsers([]);
      setUserDropdownData([]);
    }
    sethotelChangeLoading(false);
  }

  function selectedUser(event: any) {
    let abc: any = [];
    if (event.length) {
      for (let index = 0; index < event.length; index++) {
        abc.push(event[index].value);
      }
      setInputs({
        ...inputs,
        ["userIDs"]: abc.toString(),
      });
    } else {
      setInputs({
        ...inputs,
        ["userIDs"]: "",
      });
    }
  }

  function selectedSource(event: any) {
    let abc: any = [];
    if (event.length) {
      for (let index = 0; index < event.length; index++) {
        abc.push(event[index].label);
      }
      setInputs({
        ...inputs,
        ["source"]: abc.toString(),
      });
    } else {
      setInputs({
        ...inputs,
        ["source"]: "",
      });
    }
  }

  async function getAllDropdown() {
    let inputs = {
      category: "Source",
      clientID: clientID,
    };
    setLoading(true);
    const res: any = await getProjectTermByCategory(inputs, Token);
    setLoading(false);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data.data !== null && res.data.data !== undefined) {
          setAllDropdown(res.data.data.dataList1);
          setLoading(false);
        }
      }
    }
  }

  async function handleGenerateReport() {
    let obj = fnCheckValidationOfObject(inputs);
    setInputs({
      ...obj.obj,
    });

    if (obj.isValid) {
      let input: any = {
        oppoOwnerIDs: inputs.userIDs,
        propertyIDs: inputs.propertyIDs,
        sources: inputs.source,
        startDate: inputs.startDate,
        endDate: inputs.endDate,
      };

      setOppoBySourceReport(input);
      setModalShow(true);
    }
  }

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  function handleDateRange(e) {
    let [startDate, endDate] = e;
    if (startDate !== null && endDate !== null) {
      let x = moment(startDate).format("MM-DD-YYYY");
      let y = moment(endDate).format("MM-DD-YYYY");

      setInputs({
        ...inputs,
        ["startDate"]: x,
        ["endDate"]: y,
      });
    } else {
      setInputs({
        ...inputs,
        ["startDate"]: "",
        ["endDate"]: "",
      });
    }
  }

  return (
    <div className="report-page">
      <Row>
        <Col xxl={3} xl={3} lg={3} md={4}>
          <ReportNavigations />
        </Col>
        <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
          {OpportunityBySource_page_rights.is_view ? (
            !loading ? (
              <>
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col lg={12} className="mb-4">
                      <div className={`${inputs.errors.propertyIDs && "red-border-masking"}`}>
                        <FloatingLabel label="">
                          <Select
                            placeholder="Select Property"
                            options={HotelsDropdown}
                            isMulti
                            value={selHotels}
                            defaultValue={HotelsDropdown.filter(
                              (itm) => inputs.propertyIDs && itm.value.includes(inputs.propertyIDs)
                            )}
                            // onChange={(event: any) => {
                            //   setSelHotels(event);
                            //   SelectedPropertyData(event);
                            // }}
                            onChange={(event: any) => {
                              if (event.length) {
                                let data = event.filter((itm) => itm.label === "All Hotels");
                                HotelsDropdown.splice(data, 1);
                                if (data.length) {
                                  SelectedPropertyData(HotelsDropdown);
                                  setSelHotels(HotelsDropdown);
                                } else {
                                  setSelHotels(event);
                                  SelectedPropertyData(event);
                                }
                              } else {
                                setSelHotels([]);
                                SelectedPropertyData([]);
                              }
                            }}
                          ></Select>
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {inputs.errors.propertyIDs && inputs.errors.propertyIDs}{" "}
                      </small>
                    </Col>
                    <Col lg={12} className="mb-4">
                      <div className={`${inputs.errors.userIDs && "red-border-masking"}`}>
                        <FloatingLabel controlId="selectUser" label="">
                          <Select
                            placeholder="Select User"
                            options={UsersDropdown}
                            isMulti
                            value={selectedUsers}
                            onChange={(e: any) => {
                              // selectedUser(e);
                              // setSelectedUsers(e);

                              let tmpItems = e;
                              let data = tmpItems.filter((item: any) => item.label === "All Users");
                              // For All user Selection
                              if (data.length) {
                                let userIds = "";
                                let tmpUsersList = [...UsersDropdown];
                                tmpUsersList.splice(data, 1);
                                tmpUsersList.forEach((id_user: any) => {
                                  userIds = userIds.length
                                    ? userIds + "," + id_user.value
                                    : id_user.value;
                                });
                                setInputs({
                                  ...inputs,
                                  ["userIDs"]: userIds,
                                });

                                setSelectedUsers(tmpUsersList);
                              } else {
                                // For Single - Single Selection
                                let userIds_ = "";
                                tmpItems.forEach((id_user: any) => {
                                  userIds_ = userIds_.length
                                    ? userIds_ + "," + id_user.value
                                    : id_user.value;
                                });
                                setInputs({
                                  ...inputs,
                                  ["userIDs"]: userIds_,
                                });

                                setSelectedUsers(tmpItems);
                              }
                            }}
                          ></Select>
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {inputs.errors.userIDs && inputs.errors.userIDs}{" "}
                      </small>
                    </Col>

                    <Col lg={6} xl={6} className="mb-4">
                      <div className={`${inputs.errors.source && "red-border-masking"}`}>
                        <FloatingLabel controlId="floatingSelectGrid" label="">
                          <Select
                            isMulti
                            placeholder="Select Source"
                            options={optionsSource}
                            value={selectedSourceList}
                            onChange={(e: any) => {
                              // selectedSource(e);

                              let tmpItems = e;
                              let data = tmpItems.filter(
                                (item: any) => item.label === "All Source"
                              );
                              // For All Source Selection
                              if (data.length) {
                                let sourceList = "";
                                let tmpSourceList = [...optionsSource];
                                tmpSourceList.splice(data, 1);
                                tmpSourceList.forEach((id_user: any) => {
                                  sourceList = sourceList.length
                                    ? sourceList + "," + id_user.label
                                    : id_user.label;
                                });

                                setInputs({
                                  ...inputs,
                                  ["source"]: sourceList,
                                });

                                setSelectedSourceList(tmpSourceList);
                              } else {
                                // For Single - Single Selection
                                let sourceList_ = "";
                                tmpItems.forEach((id_user: any) => {
                                  sourceList_ = sourceList_.length
                                    ? sourceList_ + "," + id_user.label
                                    : id_user.label;
                                });
                                setInputs({
                                  ...inputs,
                                  ["source"]: sourceList_,
                                });
                                setSelectedSourceList(tmpItems);
                              }
                            }}
                          />
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {inputs.errors.source && inputs.errors.source}
                      </small>
                    </Col>

                    <Col lg={6} className="mb-4">
                      <div className={`${inputs.errors.startDate && "red-border-masking"}`}>
                        <div className="form-floating">
                          <DatePicker
                            className="form-control form-control-date datepicker-react"
                            placeholderText="Select Start & End Date"
                            minDate={new Date("2010/01/01")}
                            maxDate={new Date("9999/12/31")}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                              handleDateRange(update);
                              setDateRange(update);
                            }}
                          />
                        </div>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {inputs.errors.startDate && inputs.errors.startDate}{" "}
                      </small>{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Modal.Footer className="border-0 justify-content-start">
                        <Button
                          variant="primary ms-0"
                          onClick={() => {
                            if (OpportunityBySource_page_rights.is_view) {
                              handleGenerateReport();
                            } else {
                              CommonService.unauthorizedAction();
                            }
                          }}
                        >
                          <span className="me-2">
                            <img src={GenerateReport} alt="" />
                          </span>
                          Generate Report
                        </Button>
                      </Modal.Footer>
                    </Col>
                  </Row>
                  {modalShow && (
                    <OpportunityBySourceReportModal
                      OppoBySourceReport={OppoBySourceReport}
                      reportTitle={inputs}
                      show={modalShow}
                      onHide={() => {
                        setModalShow(false);
                        setOppoBySourceReport({});
                      }}
                    ></OpportunityBySourceReportModal>
                  )}
                </div>
              </>
            ) : (
              <Col xxl={12} xl={12} lg={12} md={12} className="mt-4 mt-lg-0">
                <PageLoader />
              </Col>
            )
          ) : (
            <Unauthorized />
          )}
        </Col>
      </Row>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default OpportunityBySourceReport;
