export const BreadCrumb = {
  "/home": [
    {
      name: "Home",
      link: "/home",
      active: true,
    },
  ],
  "/MarketingPlan": [
    {
      name: "Marketing Plan",
      link: "/MarketingPlan",
      active: true,
    },
  ],
  "/MonthlyReportInput": [
    {
      name: "Monthly Report Input",
      link: "/MarketingPlan",
      active: true,
    },
  ],

  "/calendar": [
    // {
    //     name: 'Miscellaneous',
    //     // link: '/miscellaneous/calendar',
    //     link: '/calendar',
    //     active: false
    // },
    {
      name: "Calender",
      // link: '/miscellaneous/calendar',
      link: "/calendar",
      active: true,
    },
  ],
  "/miscellaneous/uploadDocument": [
    {
      name: "Miscellaneous",
      link: "/miscellaneous/uploadDocument",
      active: false,
    },
    {
      name: "Upload Document",
      link: "/miscellaneous/uploadDocument",
      active: true,
    },
  ],
  "/opportunity-group/calendar": [
    {
      name: "Group Block View",
      link: "/opportunity-group/calendar",
      active: true,
    },
  ],
  "/meeting-catering/calendar": [
    {
      name: "Meeting Room Block View",
      link: "/meeting-catering/calendar",
      active: true,
    },
  ],
  "/user-profile": [
    {
      name: "Profile",
      link: "/user-profile",
      active: true,
    },
  ],
  "/lead": [
    {
      name: "Lead List",
      link: "/lead",
      active: true,
    },
  ],
  "/suspect/details": [
    {
      name: "Lead List",
      link: "/lead",
      active: false,
    },
    {
      name: "Lead View",
      link: "/suspect/details",
      active: true,
    },
  ],
  "/account": [
    {
      name: "Account List",
      link: "/account",
      active: true,
    },
  ],
  "/accounts-view": [
    {
      name: "Account List",
      link: "/account",
      active: false,
    },
    {
      name: "Account View",
      link: "/accounts-view",
      active: true,
    },
  ],
  "/contact": [
    {
      name: "Contact List",
      link: "/contact",
      active: true,
    },
  ],
  "/contact/details": [
    {
      name: "Contact List",
      link: "/contact",
      active: false,
    },
    {
      name: "Contact View",
      link: "/contact/details",
      active: true,
    },
  ],
  "/activityList": [
    {
      name: "Activity List",
      link: "/activityList",
      active: true,
    },
  ],
  "/activity/details": [
    {
      name: "Activity List",
      link: "/activityList",
      active: false,
    },
    {
      name: "Activity View",
      link: "/activity/details",
      active: true,
    },
  ],
  "/opportunity/rfp": [
    {
      name: "Opportunity",
      link: "/opportunity/rfp",
      active: false,
    },
    {
      name: "RFP List",
      link: "/opportunity/rfp",
      active: true,
    },
  ],
  "/Opportunity/RFP/details": [
    {
      name: "Opportunity",
      link: "/opportunity/rfp",
      active: false,
    },
    {
      name: "RFP List",
      link: "/opportunity/rfp",
      active: false,
    },
    {
      name: "Opportunity View",
      link: "/Opportunity/details",
      active: true,
    },
  ],
  "/Opportunity/LNR/details": [
    {
      name: "Opportunity",
      link: "/opportunity/lnr",
      active: false,
    },
    {
      name: "LNR List",
      link: "/opportunity/lnr",
      active: false,
    },
    {
      name: "Opportunity View",
      link: "/Opportunity/details",
      active: true,
    },
  ],
  "/Opportunity/Group/details": [
    {
      name: "Opportunity",
      link: "/opportunity/group",
      active: false,
    },
    {
      name: "Group List",
      link: "/opportunity/group",
      active: false,
    },
    {
      name: "Opportunity View",
      link: "/Opportunity/details",
      active: true,
    },
  ],
  "/Opportunity/MeetingAndCatering/details": [
    {
      name: "Opportunity",
      link: "/opportunity/meetingAndCatering",
      active: false,
    },
    {
      name: "Meeting & Catering List",
      link: "/opportunity/meetingAndCatering",
      active: false,
    },
    {
      name: "Opportunity View",
      link: "/Opportunity/details",
      active: true,
    },
  ],
  "/Opportunity/details": [
    {
      OppoID: [
        {
          name: "Opportunity",
          link: "/opportunity/rfp",
          active: false,
        },
        {
          name: "RFP List",
          link: "/opportunity/rfp",
          active: false,
        },
        {
          name: "Opportunity View",
          link: "/Opportunity/details",
          active: true,
        },
      ],
      LNROppoID: [
        {
          name: "Opportunity",
          link: "/opportunity/lnr",
          active: false,
        },
        {
          name: "LNR List",
          link: "/opportunity/lnr",
          active: false,
        },
        {
          name: "Opportunity View",
          link: "/Opportunity/details",
          active: true,
        },
      ],
      GroupOppoID: [
        {
          name: "Opportunity",
          link: "/opportunity/group",
          active: false,
        },
        {
          name: "Group List",
          link: "/opportunity/group",
          active: false,
        },
        {
          name: "Opportunity View",
          link: "/Opportunity/details",
          active: true,
        },
      ],
      MACOppoID: [
        {
          name: "Opportunity",
          link: "/opportunity/meetingAndCatering",
          active: false,
        },
        {
          name: "Meeting & Catering List",
          link: "/opportunity/meetingAndCatering",
          active: false,
        },
        {
          name: "Opportunity View",
          link: "/Opportunity/details",
          active: true,
        },
      ],
      general: [
        {
          name: "Opportunity",
          link: "/opportunity/rfp",
          active: false,
        },
        {
          name: "RFP List",
          link: "/opportunity/rfp",
          active: false,
        },
        {
          name: "Opportunity View",
          link: "/Opportunity/details",
          active: true,
        },
      ],
    },
  ],
  "/opportunity/lnr": [
    {
      name: "Opportunity",
      link: "/opportunity/lnr",
      active: false,
    },
    {
      name: "LNR List",
      link: "/opportunity/lnr",
      active: true,
    },
  ],
  "/opportunity/meetingAndCatering": [
    {
      name: "Opportunity",
      link: "/opportunity/meetingAndCatering",
      active: false,
    },
    {
      name: "Meeting & Catering List",
      link: "/opportunity/meetingAndCatering",
      active: true,
    },
  ],
  "/opportunity/group": [
    {
      name: "Opportunity",
      link: "/opportunity/group",
      active: false,
    },
    {
      name: "Group List",
      link: "/opportunity/group",
      active: true,
    },
  ],
  "/SetupAndConfigurations/companyProfile": [
    {
      name: "Setup and Configuration",
      link: "/SetupAndConfigurations/companyProfile",
      active: false,
    },
    {
      name: "Company Profile",
      link: "/SetupAndConfigurations/companyProfile",
      active: true,
    },
  ],
  "/SetupAndConfigurations/generalsetting": [
    {
      name: "Setup and Configuration",
      link: "/SetupAndConfigurations/generalsetting",
      active: false,
    },
    {
      name: "General Setting",
      link: "/SetupAndConfigurations/generalsetting",
      active: true,
    },
  ],
  // "/SetupAndConfigurations/mailsetting": [
  //   {
  //     name: "Setup and Configuration",
  //     link: "/SetupAndConfigurations/mailsetting",
  //     active: false,
  //   },
  //   {
  //     name: "Mail Setting",
  //     link: "/SetupAndConfigurations/mailsetting",
  //     active: true,
  //   },
  // ],
  "/SetupAndConfigurations/portfolios": [
    {
      name: "Setup and Configuration",
      link: "/SetupAndConfigurations/portfolios",
      active: false,
    },
    {
      name: "Portfolio List",
      link: "/SetupAndConfigurations/portfolios",
      active: true,
    },
  ],
  "/SetupAndConfigurations/hotels": [
    {
      name: "Setup and Configuration",
      link: "/SetupAndConfigurations/hotels",
      active: false,
    },
    {
      name: "Hotel List",
      link: "/SetupAndConfigurations/hotels",
      active: true,
    },
  ],
  "/SetupAndConfigurations/hotels/hotelDetails/:id": [
    {
      name: "Setup and Configuration",
      link: "/SetupAndConfigurations/hotels",
      active: false,
    },
    {
      name: "Hotel List",
      link: "/SetupAndConfigurations/hotels",
      active: false,
    },
    {
      name: "Hotel View",
      link: "/SetupAndConfigurations/hotels/hotelDetails/:id",
      active: true,
    },
  ],
  "/SetupAndConfigurations/user": [
    {
      name: "Setup and Configuration",
      link: "/SetupAndConfigurations/user",
      active: false,
    },
    {
      name: "User Management",
      link: "/SetupAndConfigurations/user",
      active: false,
    },
    {
      name: "User List",
      link: "/SetupAndConfigurations/user",
      active: true,
    },
  ],
  "/SetupAndConfigurations/userRole": [
    {
      name: "Setup and Configuration",
      link: "/SetupAndConfigurations/userRole",
      active: false,
    },
    {
      name: "User Management",
      link: "/SetupAndConfigurations/userRole",
      active: false,
    },
    {
      name: "User Role List",
      link: "/SetupAndConfigurations/userRole",
      active: true,
    },
  ],
  "/SetupAndConfigurations/accountRules": [
    {
      name: "Setup and Configuration",
      link: "/SetupAndConfigurations/accountRules",
      active: false,
    },
    {
      name: "Account Rules",
      link: "/SetupAndConfigurations/accountRules",
      active: true,
    },
  ],
  "/SetupAndConfigurations/marketSegmentType": [
    {
      name: "Setup and Configuration",
      link: "/SetupAndConfigurations/marketSegmentType",
      active: false,
    },
    {
      name: "Market Segment List",
      link: "/SetupAndConfigurations/marketSegmentType",
      active: true,
    },
  ],
  "/SetupAndConfigurations/additionalSettings": [
    {
      name: "Setup and Configuration",
      link: "/SetupAndConfigurations/additionalSettings",
      active: false,
    },
    {
      name: "Additional Settings",
      link: "/SetupAndConfigurations/additionalSettings",
      active: true,
    },
  ],
  "/Reports": [
    {
      name: "Dashboard Weekly Report",
      link: "/Reports",
      active: true,
    },
  ],
  "/Import": [
    {
      name: "Import",
      link: "/Import",
      active: true,
    },
  ],
  "/DashboardWeeklyReport": [
    {
      name: "Dashboard Weekly Report",
      link: "/DashboardWeeklyReport",
      active: true,
    },
  ],
  "/WeeklyReport": [
    {
      name: "Weekly Report",
      link: "/WeeklyReport",
      active: true,
    },
  ],
  "/multiple-property-weekly-report": [
    {
      name: "Multiple Property Weekly Report",
      link: "/multiple-property-weekly-report",
      active: true,
    },
  ],
  "/MarketingPlanReport": [
    {
      name: "Marketing Plan Reports",
      link: "/MarketingPlanReport",
      active: true,
    },
  ],
  "/ActivitiesReport": [
    {
      name: "Activities Reports",
      link: "/ActivitiesReport",
      active: true,
    },
  ],
  "/MonthlyReport": [
    {
      name: "Monthly Reports",
      link: "/MonthlyReport",
      active: true,
    },
  ],
  "/MonthlyPortfolioReport": [
    {
      name: "Monthly Rollup Reports",
      link: "/MonthlyPortfolioReport",
      active: true,
    },
  ],
  "/RFPReport": [
    {
      name: "RFP Grid Reports",
      link: "/RFPReport",
      active: true,
    },
  ],
  "/LNRGridTable": [
    {
      name: "LNR Grid Reports",
      link: "/LNRGridTable",
      active: true,
    },
  ],
  "/GroupGridTable": [
    {
      name: "Group Grid Reports",
      link: "/GroupGridTable",
      active: true,
    },
  ],
  "/MeetingCateringGridTable": [
    {
      name: "Meeting & Catering Grid Reports",
      link: "/MeetingCateringGridTable",
      active: true,
    },
  ],
  "/MeeingAndCateringMonthViewReport": [
    {
      name: "Meeing Room Booked Reports",
      link: "/MeeingAndCateringMonthViewReport",
      active: true,
    },
  ],
  "/GroupMonthViewReport": [
    {
      name: "Group Room Count(GRC) Reports",
      link: "/GroupMonthViewReport",
      active: true,
    },
  ],
  "/OpportunityBySourceReport": [
    {
      name: "Opportunity By Source Report Reports",
      link: "/OpportunityBySourceReport",
      active: true,
    },
  ],
  "/MeetingAndCateringByDate": [
    {
      name: "Meeting & Catering By Date Reports",
      link: "/MeetingAndCateringByDate",
      active: true,
    },
  ],
  "/GroupPaceReport": [
    {
      name: "Group Pace Reports",
      link: "/GroupPaceReport",
      active: true,
    },
  ],
  "/MeetingPaceReport": [
    {
      name: "Meeting Pace Reports",
      link: "/MeetingPaceReport",
      active: true,
    },
  ],

  "/LeadReport": [
    {
      name: "Lead Report",
      link: "/LeadReport",
      active: true,
    },
  ],

  "/ActualReport": [
    {
      name: "Actual Report",
      link: "/ActualReport",
      active: true,
    },
  ],

  "/AccountReport": [
    {
      name: "Account Report",
      link: "/AccountReport",
      active: true,
    },
  ],

  "/LostBusinessGridReport": [
    {
      name: "Lost Business Grid Reports",
      link: "/LostBusinessGridReport",
      active: true,
    },
  ],
  "/TraceReport": [
    {
      name: "Trace Reports",
      link: "/TraceReport",
      active: true,
    },
  ],
  "/ActivityGoalsReport": [
    {
      name: "Activity Goals Reports",
      link: "/ActivityGoalsReport",
      active: true,
    },
  ],
  "/OpportunitySalesFunnelReport": [
    {
      name: "Opportunity Sales Funnel Report",
      link: "/OpportunitySalesFunnelReport",
      active: true,
    },
  ],
  "/support": [
    {
      name: "Support",
      link: "/support",
      active: true,
    },
  ],
  "/support/details": [
    {
      name: "Support",
      link: "/support",
      active: false,
    },
    {
      name: "Support View",
      link: "/support/details",
      active: true,
    },
  ],
  "/knowledge-tutorials": [
    {
      name: "Knowledge-Tutorials",
      link: "/knowledge-tutorials",
      active: true,
    },
  ],
  "/new-user-tour": [
    {
      name: "Knowledge-Tutorials",
      link: "/new-user-tour",
      active: true,
    },
  ],
  "/adding-new-account-tour": [
    {
      name: "Knowledge-Tutorials",
      link: "/adding-new-account-tour",
      active: true,
    },
  ],
  "/new-suspect-or-lead-tour": [
    {
      name: "Knowledge-Tutorials",
      link: "/new-suspect-or-lead-tour",
      active: true,
    },
  ],
  "/adding-new-contacts-tour": [
    {
      name: "Knowledge-Tutorials",
      link: "/adding-new-contacts-tour",
      active: true,
    },
  ],
  "/opportunity-build-tour": [
    {
      name: "Knowledge-Tutorials",
      link: "/opportunity-build-tour",
      active: true,
    },
  ],

  // Gmail Breadcrums
  "/inbox": [
    {
      name: "Inbox",
      link: "/inbox",
      active: true,
    },
  ],
  "/all-mails": [
    {
      name: "All-mails",
      link: "/all-mails",
      active: true,
    },
  ],
  "/archive-mails": [
    {
      name: "Archive-mails",
      link: "/archive-mails",
      active: true,
    },
  ],
  "/draft-mails": [
    {
      name: "Draft-mails",
      link: "/draft-mails",
      active: true,
    },
  ],
  "/starred-mails": [
    {
      name: "Starred-mails",
      link: "/starred-mails",
      active: true,
    },
  ],
  "/important-mails": [
    {
      name: "Important-mails",
      link: "/important-mails",
      active: true,
    },
  ],
  "/spam-mails": [
    {
      name: "Spam-mails",
      link: "/spam-mails",
      active: true,
    },
  ],
  "/sent-mails": [
    {
      name: "Sent-mails",
      link: "/sent-mails",
      active: true,
    },
  ],
  "/trash-mails": [
    {
      name: "Trash-mails",
      link: "/trash-mails",
      active: true,
    },
  ],

  // Gmail Breadcrum
  "/gmail": [
    {
      name: "Gmail",
      link: "/gmail",
      active: true,
    },
  ],

  // Microsoft Breadcrum
  "/microsoft": [
    {
      name: "Microsoft",
      link: "/microsoft",
      active: true,
    },
  ],

  // Import Breadcrum
  "/import": [
    {
      name: "Import",
      link: "/import",
      active: true,
    },
  ],
};
