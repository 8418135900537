import { ENUMS } from "../../Constants";
import { dashboardInitState } from "../../Constants/InitialStates";

export default function dashboardReducer(state = dashboardInitState, action) {
  switch (action.type) {
    case ENUMS.actions.DASHBOARD_DETAILS:
      return { ...state, ...action.payload };

    case ENUMS.actions.YEAR_DETAILS:
      return { ...state, Year: action.payload };

    case ENUMS.actions.SELECTED_LOGO:
      return { ...state, SelectedLogo: action.payload };

    case ENUMS.actions.SELECTED_PROPERTY:
      return { ...state, SelectedPropertyID: action.payload };

    case ENUMS.actions.SELECTED_PROPERTY_NAME:
      return { ...state, SelectedPropertyName: action.payload };

    case ENUMS.actions.SELECTED_PORTFOLIO:
      return { ...state, selectedPortfolio: action.payload };

    case ENUMS.actions.SELECTED_PORTFOLIO_ID:
      return { ...state, selectedPortfolioID: action.payload };

    case ENUMS.actions.GLOBAL_HOTELS:
      return { ...state, GlobalHotels: action.payload };

    case ENUMS.actions.ISLOGOCHANGEDATA:
      return { ...state, isLogochangeData: action.payload };

    case ENUMS.actions.SELECTED_ACCOUNT:
      return { ...state, SelectedAccount: action.payload };

    case ENUMS.actions.ALL_CLIENTS:
      return {
        ...state,
        all_clients: action.payload,
      };

    default:
      return state;
  }
}
