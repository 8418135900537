import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import Select from "react-select";
import GenerateReport from "../../Asset/Images/Other/generate-report.png";
import PageLoader from "../../Component/Loader/PageLoader";
import ActivityReportModal from "../../Model/Reports/ActivityReportModal";
import {
  AppConfigData,
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  fnCheckValidationOfObject,
} from "../../Services/CommonServices";
import { getAccountDropdownData } from "../../Services/DashboardServices";
import { GetHotelsListForReports } from "../../Services/Setup&Config/HotelService";
import Unauthorized from "../Authentication/Unauthorized";
import ReportNavigations from "./ReportNavigations";
import { getActivitiesReportListExportPDF } from "../../Services/ReportServices";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";

function ActivityReport() {
  let ActivitiesReport_page_rights = CommonService.getPageRight("ActivitiesReport");

  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;

  const [LoadProcess, setLoadProcess] = useState(false);

  const [accountListData, setaccountListData]: any = useState([]);
  const [showModal, setshowModal]: any = useState(false);
  const [loading, setloading]: any = useState(false);
  const [ReportData, setReportData]: any = useState({
    propertyIDs: "",
    propertyName: "",
    startDate: null,
    endDate: null,
    accountID: null,
    errors: {
      propertyIDs: "",
      startDate: "",
      endDate: "",
      ValidationRules: [
        {
          FieldName: "propertyIDs",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "startDate",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "endDate",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });
  const [selHotels, setSelHotels]: any = useState([]);
  const [selAccount, setSelAccount]: any = useState([]);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  let [PropertiesListData, setPropertiesListData]: any = useState({
    IsData: true,
    data: [],
  });
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("activityReportFilter"))) {
      HandleRemoveOtherFilters("activityReportFilter");
      HandleFilters("activityReportFilter", {
        pagePath: "/ActivitiesReport",
        pageName: "activityReport",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  useEffect(() => {
    if (SelectedPropertyName) {
      if (!SelectedPropertyID.includes(",")) {
        setSelHotels([
          {
            value: SelectedPropertyID,
            label: SelectedPropertyName,
          },
        ]);

        setReportData({
          ...ReportData,
          propertyIDs: SelectedPropertyID,
        });
        getAccountsDDTData(SelectedPropertyID);
      }
    }

    getHotelsList();
  }, [SelectedPropertyName]);

  let HotelsDropdown: any = [];

  PropertiesListData.data !== undefined &&
    PropertiesListData.data !== null &&
    PropertiesListData.data.length > 0 &&
    PropertiesListData.data.map((item: any) => {
      HotelsDropdown.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      userID: userId,
    };
    setPropertiesListData({ IsData: true, data: [] });
    let res: any = await GetHotelsListForReports(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setPropertiesListData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPropertiesListData({ IsData: false, data: [] });
        }
      } else {
        setPropertiesListData({ IsData: false, data: [] });
      }
    } else {
      setPropertiesListData({ IsData: false, data: [] });
    }
  }

  let optionsAccount: any = [
    {
      label: "All Accounts",
      value: "All Accounts",
    },
  ];

  accountListData.length > 0 &&
    accountListData.map((item: any) => {
      optionsAccount.push({
        label: item.AccountName,
        value: item.AccountID,
      });
    });
  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  function SelectHotel(event: any) {
    if (event) {
      setSelAccount([]);

      setReportData({
        ...ReportData,
        propertyIDs: event.value,
        propertyName: event.label,
        accountID: null,
      });
      getAccountsDDTData(event.value);
    } else {
      setSelAccount([]);
      setReportData({
        ...ReportData,
        propertyIDs: "",
        accountID: null,
      });
      optionsAccount = [];
      setaccountListData([]);
    }
  }

  async function getAccountsDDTData(selectedHotelId) {
    sethotelChangeLoading(true);
    if (selectedHotelId) {
      let input: any = {
        propertiesIDs: null,
      };
      if (selectedHotelId !== undefined && selectedHotelId !== null && selectedHotelId !== "") {
        input.propertiesIDs = selectedHotelId;
      }

      let res: any = await getAccountDropdownData(input, Token);

      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            setaccountListData(res.data.data.dataList1);
          }
        }
      }
    }
    sethotelChangeLoading(false);
  }

  async function handleGenerateReport() {
    let obj = fnCheckValidationOfObject(ReportData);
    setReportData({
      ...obj.obj,
    });
    if (obj.isValid) {
      setshowModal(true);
    }
  }

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  function handleDateRange(e) {
    let [startDate, endDate] = e;
    if (startDate !== null && endDate !== null) {
      let x = moment(startDate).format("MM-DD-YYYY");
      let y = moment(endDate).format("MM-DD-YYYY");

      setReportData({
        ...ReportData,
        ["startDate"]: x,
        ["endDate"]: y,
      });
    } else {
      setReportData({
        ...ReportData,
        ["startDate"]: "",
        ["endDate"]: "",
      });
    }
  }

  const exportActivitiesReportPDF = async () => {
    let obj = fnCheckValidationOfObject(ReportData);
    setReportData({
      ...obj.obj,
    });
    if (obj.isValid) {
      let input: any = {
        propertyID: ReportData.propertyIDs,
        accountIDs: ReportData.accountID,
        startDate: ReportData.startDate,
        endDate: ReportData.endDate,
      };

      setLoadProcess(true);
      let res: any = await getActivitiesReportListExportPDF(input, Token);
      setLoadProcess(false);
      if (res.data.success) {
        if (res.data.statusCode === 200) {
          if (res.data.data !== "" && res.data.data !== null && res.data.data !== undefined) {
            window.open(AppConfigData.APIBasePath + res.data.data);
            // props.onHide(false);
          } else {
            ToasterError(res.data.message);
          }
        } else {
          ToasterError("Something Went Wrong.");
        }
      } else {
        ToasterError("Something Went Wrong.");
      }
    }
  };

  return (
    <>
      <div className="report-page">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={4}>
            <ReportNavigations />
          </Col>

          <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
            {ActivitiesReport_page_rights.is_view ? (
              PropertiesListData.IsData === false ? (
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col xs={12} className="mb-3">
                          <Form.Group>
                            <div
                              className={`${ReportData.errors.propertyIDs && "red-border-masking"}`}
                            >
                              <FloatingLabel controlId="floatingSelectGrid" label="">
                                <Select
                                  styles={{
                                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                  }}
                                  placeholder="Select Hotels"
                                  options={HotelsDropdown}
                                  // value={selHotels}
                                  defaultValue={HotelsDropdown.filter(
                                    (itm) =>
                                      ReportData.propertyIDs &&
                                      itm.value.includes(ReportData.propertyIDs)
                                  )}
                                  onChange={(e) => {
                                    setSelHotels(e);
                                    SelectHotel(e);
                                  }}
                                />
                              </FloatingLabel>
                            </div>
                            <small style={{ color: "#dc3545" }}>
                              {" "}
                              {ReportData.errors.propertyIDs && ReportData.errors.propertyIDs}{" "}
                            </small>
                          </Form.Group>
                        </Col>
                        <Col xs={12} className="mb-3">
                          <Form.Group>
                            <FloatingLabel controlId="floatingSelectGrid" label="">
                              <Select
                                styles={{
                                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                }}
                                placeholder="Select Account"
                                options={optionsAccount}
                                isMulti
                                value={selAccount}
                                onChange={(event) => {
                                  let tmpItems = event;
                                  let data: any = tmpItems.filter(
                                    (item: any) => item.label === "All Accounts"
                                  );
                                  // For All user Selection
                                  if (data.length) {
                                    let userIds = "";
                                    let tmpUsersList = [...optionsAccount];
                                    tmpUsersList.splice(data, 1);
                                    tmpUsersList.forEach((id_user: any) => {
                                      userIds = userIds.length
                                        ? userIds + "," + id_user.value
                                        : id_user.value;
                                    });
                                    setReportData({
                                      ...ReportData,
                                      accountID: userIds ? userIds : null,
                                    });
                                    setSelAccount(tmpUsersList);
                                  } else {
                                    // For Single - Single Selection
                                    let userIds = "";
                                    tmpItems.forEach((id_user: any) => {
                                      userIds = userIds.length
                                        ? userIds + "," + id_user.value
                                        : id_user.value;
                                    });

                                    setReportData({
                                      ...ReportData,
                                      accountID: userIds ? userIds : null,
                                    });
                                    setSelAccount(tmpItems);
                                  }

                                  // setSelAccount(event);
                                  // let tempAccount: any = [];
                                  // if (event.length) {
                                  //   Array.isArray(event)
                                  //     ? event.map((x) => {
                                  //         tempAccount.push(x.value);
                                  //       })
                                  //     : [];
                                  //   //
                                  //   setReportData({
                                  //     ...ReportData,
                                  //     accountID: tempAccount.toString(),
                                  //   });
                                  // } else {
                                  //   setReportData({
                                  //     ...ReportData,
                                  //     accountID: null,
                                  //   });
                                  // }
                                }}
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>

                        <Col lg={6} xl={6} className="mb-4">
                          <div className={`${ReportData.errors.startDate && "red-border-masking"}`}>
                            <div className="form-floating">
                              <DatePicker
                                className="form-control form-control-date datepicker-react"
                                placeholderText="Select Start & End Date"
                                minDate={new Date("2010/01/01")}
                                maxDate={new Date("9999/12/31")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                  handleDateRange(update);
                                  setDateRange(update);
                                }}
                              />
                            </div>
                          </div>
                          <small style={{ color: "#dc3545" }}>
                            {ReportData.errors.startDate && ReportData.errors.startDate}{" "}
                          </small>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12}>
                          <Modal.Footer className="border-0 justify-content-start">
                            <Button
                              variant="primary ms-0"
                              onClick={() => {
                                if (ActivitiesReport_page_rights.is_view) {
                                  handleGenerateReport();
                                } else {
                                  CommonService.unauthorizedAction();
                                }
                              }}
                            >
                              <span className="me-2">
                                <img src={GenerateReport} alt="" />
                              </span>
                              Generate Report
                            </Button>

                            <Button
                              variant="primary ms-0"
                              disabled={LoadProcess === false ? false : true}
                              onClick={(e) => {
                                if (ActivitiesReport_page_rights.is_export) {
                                  e.preventDefault();
                                  exportActivitiesReportPDF();
                                } else {
                                  CommonService.unauthorizedAction();
                                }
                              }}
                            >
                              <span className="me-2">
                                <img src={PdfIcon} alt="" />
                              </span>
                              Export PDF
                            </Button>
                          </Modal.Footer>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              ) : (
                <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
                  <PageLoader />
                </Col>
              )
            ) : (
              <Unauthorized />
            )}
          </Col>
        </Row>
        {showModal && (
          <ActivityReportModal
            ReportData={ReportData}
            show={showModal}
            onHide={() => setshowModal(false)}
          />
        )}
      </div>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default ActivityReport;
